.assets {
    width: 100%;
    height: 100%;
    border-radius: 24px;
}

.block {
    box-shadow: 3px 6px 6px #00000029;
    border: 1px solid #707070;
    border-radius: 24px;
    height: 26vh;
    max-width: 26vh;
    margin-bottom: 20px;
}

.blockMedia {
    position: fixed;
    right: 2vw;
    max-width: 30vw;
    width: 30vw;
    height: 26vh;
    bottom: 2vh;
    left: 35vw;
}

.participantsList {
    height: 78vh;
    width: 10vw;
    position: absolute;
    right: 0;
    top: 10vh;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
}

.whiteboardLeft {
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    width: 50%;
    justify-content: center;
    align-items: flex-end;
    display: flex;
    padding: 0px;
    flex-direction: column;
}

.whiteboardRight {
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    width: 50%;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    padding: 0px;
    flex-direction: column;
}

.wbContainerLeft {
    border-radius: 25px 0 0 25px;
    width: 100%;
    max-width: 617px;
    border: 1px solid #707070;
    background: #fff;
    border-right: 0;
    cursor: url("../../../../../../../assets/images/Clicker.svg"), pointer;
}

.wbContainerRight {
    border-radius: 0 25px 25px 0;
    width: 100%;
    max-width: 617px;
    border: 1px solid #707070;
    background: #fff;
    cursor: url("../../../../../../../assets/images/Clicker.svg"), pointer;
}

@media only screen and (max-width: 1275px) {
    .block {
        display: none;
    }

    .whiteboardLeft {
        align-items: flex-start;
    }
}

@media only screen and (max-height: 698px) {
    .block {
        display: none;
    }
}

.centerText {
    margin-top: auto;
    margin-bottom: auto;
    height: max-content;
}

.whiteboardRow {
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 24px;
}
