.logoImg {
    position: fixed;
    width: 38vw;
    left: -11vw;
    top: -17vw;
}

.logoContainer {
    position: absolute;
    top: 0;
    left: 0.96rem;
    z-index: 70;
}

.studentLogoBg {
    width: 25.15vw;
    max-width: 22.5rem;
    position: fixed;
}

.logoText,
.logoSubText {
    text-align: center;
    font-family: ComfortaBold;
    font-size: 2.58vw;
    line-height: 67px;
    letter-spacing: 0.3px;
    color: #ffffff;
    position: fixed;
    top: 2.3vw;
    left: 3.2vw;
    margin-bottom: 0;
    cursor: pointer;
}

.logoSubText {
    font-size: 2.24vw;
    top: 5.6vw;
    left: 5.7vw;
}

@media screen and (min-width: 1430px) {
    .logoText,
    .logoSubText {
        font-size: 37px;
        top: 2.3rem;
        left: 3rem;
    }

    .logoSubText {
        font-size: 32px;
        top: 5.2rem;
        left: 5.1rem;
    }
}

.studentModalBackdrop {
    opacity: 0;
}

.logoTextTeacher {
    left: 4.8rem;
    top: 5rem;
    font-size: 32px;
}

.welcomeText {
    /* font-weight: bold; */
    font-family: ComfortaBold;
    color: #002b4a;
    font-size: 35px;
    height: 47px;
    width: 100%;
    opacity: 1;
    margin-top: 31px;
    position: absolute;
    left: 50px;
}

.topRightContainer {
    align-items: center;
    float: right;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: red;
}

.navbarItems {
    /* display: inline-block; */
    /* background-color: blue; */
    float: right;
    width: 50%;
    margin-left: 80%;
    /* margin-right: 0%; */
    /* flex-direction: row; */
    /* justify-content: space-evenly; */
}

.topRight {
    background-color: yellow;
    margin-top: 1rem;
    /* float: right; */
    color: #002b4a;
    /* font-size: 30px; */
    /* height: 6vh; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* margin-top: 31px; */
}

.mainSectionTeacher {
    background-color: #fdccb9;
    height: 100vh;
}

.mainSectionStudent {
    background-color: #e3f0fa;
    height: 100vh;
}

.middleSectionContainer {
    margin-left: -1rem;
    margin-right: 1rem;
}

.middleSection {
    width: 48vw;
    padding: 20px;
    background-color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    display: flex;
}

.middleImg {
    width: 100%;
    height: 19.5rem;
    margin: auto;
}

.studentTasks,
.teacherTasks {
    width: 48vw;
    height: 15rem;
}

.rightSection {
    margin-top: 40px;
    position: absolute;
    right: 1.3rem;
}

.rightSection h1 {
    background-color: #dc531e;
    display: inline-block;
    padding: 10px;
    color: #fff;
    cursor: pointer;
}

.createBtn {
    background-color: #002b4a;
    display: inline-block;
    padding: 10px;
    color: #fff;
    cursor: pointer;
    margin-left: 40px;
}

.topIcon {
    padding-left: 5px;
    margin-top: 25px;
    /* margin-right: 10em; */
    /* float: right; */
}

.dashboardIcon {
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    cursor: pointer;
    height: 35px;
    width: 32px;
    opacity: 1;
    margin-top: 2px;
}

.hoverText {
    margin-bottom: 5px;
    /* visibility: hidden; */
    /* opacity: 0; */
}

.dashboardIcon:hover .hoverText {
    visibility: visible;
    opacity: 1;
}

.iconColumn {
    height: 20%;
    width: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -11px;
}

@media screen and (max-width: 768px) {
    .iconColumn {
        height: 20%;
        /* width: 20%; */
    }
}

.iconRow {
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 52px;
}

.createClassButton {
    box-shadow: 4px 4px 4px #00000029;
    border-radius: 26px;
    background: #88addb;
    text-align: left;
    font-size: 22px;
    line-height: 24px;
    font-family: ComfortaBold;
    letter-spacing: 0.11px;
    color: #ffffff;
    padding: 13px 18px;
    justify-content: center;
    display: flex;
    float: right;
    cursor: pointer;
}

.dashboardHeading {
    font-size: 35px;
    line-height: 39px;
    font-family: ComfortaBold;
    letter-spacing: 0.17px;
    color: #002b4a;
    margin-bottom: 7vh;
}

.dashboardColHeading {
    font-family: RobotoMedium;
    color: #dc531e;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-bottom: 2vh;
}

.classRow {
    border-radius: 21px;
    background: #e9f3fb;
    color: #707070;
    font-family: RobotoMedium;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-bottom: 1vh;
    height: 7vh;
}

.dashboardModal {
    box-shadow: 4px 4px 4px #00000029;
    border: 2px solid #e9f3fb;
    border-radius: 21px;
    padding: 5vh 2vw;
}

.dashboardModalBackdrop {
    opacity: 0;
}

.pencilIcon {
    float: right;
    cursor: pointer;
    height: 7vh;
    padding: 1vh;
}

.plusIcon {
    margin-left: 1vw;
    cursor: pointer;
    width: 7vh;
    height: 7vh;
}

.minusIcon {
    cursor: pointer;
    width: 7vh;
    height: 7vh;
}

.minusIconStudentModal {
    margin-left: 2vw;
}

.classDetail {
    margin: auto 0;
}

.studentList {
    color: #707070;
    font-family: RobotoMediumItalic;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1px;
    float: right;
    margin-top: 1vh;
    width: 100%;
    float: right;
    display: flex;
    justify-content: right;
}

.parentNames {
    font-family: RobotoMedium;
}

.invitationBox {
    box-shadow: 4px 4px 4px #00000029;
    border-radius: 21px;
    background: #c8e8e5;
    width: 70%;
    padding: 3vh 1.5vw;
    max-height: 25vh;
}

.invitationBoxText {
    color: #707070;
    font-family: RobotoMedium;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1px;
}

.inviteLink {
    border-radius: 21px;
    background: #e9f3fb;
    width: 60%;
    display: flex;
    justify-content: space-between;
    min-height: 7vh;
    flex-direction: row;
    flex-wrap: wrap;
}

.copyButton {
    float: right;
    height: 7vh;
    cursor: pointer;
}

.copyIcon {
    height: 100%;
}

.link {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 1vw;
}

.removeStudentIcon {
    border-radius: 50%;
    border: solid;
    float: right;
    height: 5vh;
    width: 5vh;
    cursor: pointer;
    display: flex;
    margin-right: 1vw;
    margin-top: auto;
    margin-bottom: auto;
}

.minusFaIcon {
    margin: auto;
}

.dropdownItem {
    cursor: pointer;
    width: 100%;
    margin-top: 1rem;
    margin-left: 1rem;
}

.backButton {
    width: 7vw;
    background: #bbbebe;
}

.emailInput {
    background: inherit;
    border: none;
    width: 70%;
    border-radius: inherit;
    height: 100%;
    margin-left: 1vw;
}

button {
    background: inherit;
    border: none;
}

.addStudentForm {
    width: 100%;
}

.angleDownFaIcon {
    color: #75a3d7;
    font-size: 30px;
    cursor: pointer;
    margin-left: 1vw;
}

.studentsDropdown {
    display: inline-block;
    z-index: 2;
    position: relative;
    border-radius: 21px;
    padding-left: 1vw;
}

.tabsHead {
    margin-left: 3%;
    border: none;
    position: absolute;
    left: 23%;
    color: red;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: red;
    background-color: #fff;
}

.nav-tabs {
    border-bottom: none;
}

.nav-tabs .nav-link:hover {
    color: gray;
}

.selectedtab {
    position: fixed;
    top: 40%;
    /* left: 15%; */
    margin-left: 120px;
    /* right: 15%; */
    width: 1066px;
    margin-top: -50px;
}

/* .tabsHead ul > li.active > a,
.tabsHead ul > li.active > a:hover,
.tabsHead ul > li.active > a:focus {
    background-color: red;
    color: white;
} */

.studentName {
    cursor: pointer;
}

.teacherName {
    margin-left: 1vw;
}

.showStudentsDropdown {
    color: #75a3d7;
    background-color: #ffffff;
}

.playButton {
    cursor: pointer;
}

.playButtonFilter {
    filter: grayscale(100%);
}

.teacherBag,
.bag {
    width: 236px;
    height: 217px;
    margin-top: 4.8vw;
    margin-left: 3vw;
    position: absolute;
    z-index: 99;
}

.bag {
    cursor: pointer;
}

.teacherMoments,
.studentMoments {
    position: absolute;
    margin-left: 3vw;
    margin-top: 16.6rem;
    height: 24rem;
    width: 238px;
}

@media screen and (min-width: 1326px) {
    .teacherBag,
    .bag {
        margin-top: 5.7vw;
        margin-left: 4vw;
    }

    .teacherMoments,
    .studentMoments {
        margin-left: 4vw;
        margin-top: 17.5rem;
    }
}

@media screen and (min-width: 1430px) {
    .teacherBag,
    .bag {
        margin-top: 5rem;
        margin-left: 4rem;
    }

    .teacherMoments,
    .studentMoments {
        margin-top: 17.6rem;
        margin-left: 4rem;
    }
}

.topRow {
    height: 6em;
    width: 100%;
    /* border: solid; */
    /* box-shadow: 0px 5px #f0ebeb; */
    -webkit-box-shadow: 0 4px 6px -6px #222;
    -moz-box-shadow: 0 4px 6px -6px #222;
    box-shadow: 0 4px 5px -6px #222;
}

.trophyImages {
    height: 100px;
    margin-right: 25px;
}

.deskContainer {
    position: relative;
    margin-top: 20px;
}

.deskTopShelf {
    top: 34px;
}

.deskBottomShelf {
    bottom: 87px;
}

.deskBottomShelfTeacher {
    bottom: 76px;
}

.deskShelf {
    display: flex;
    flex-flow: row;
    position: absolute;
    left: 10px;
}

.leftShelfItem {
    margin-right: 10px;
}

.cookieJar {
    height: 85px;
}

.islandFlag {
    margin-left: 36px;
    bottom: -25px;
    position: relative;
    height: 55px;
}

.topShelfIconContainer {
    width: 50px;
    height: 50px;
    margin: 0 3px;
}

.topShelfIcon {
    position: absolute;
    margin: 13px;
    z-index: 2;
}

.horizontalBooksContainer {
    margin-top: 15px;
}

.topShelfIcons {
    display: flex;
}

.classFileIcon {
    margin-right: 15px;
    width: 130px;
}

.videoIcon {
    margin-top: 16px;
    margin-left: 12px;
}
