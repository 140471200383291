.modal {
    border-radius: 25px !important;
    box-shadow: 4px 4px 4px #00000029;
}

.modalBackdrop {
    opacity: 0 !important;
}

.modalSubBlock {
    border: 1px solid #707070;
    border-radius: 25px;
    box-shadow: 4px 4px 4px #00000029;
    position: relative;
    background-color: #ffffff;
    margin: 10px;
    width: 100%;
    padding: 10px 25px;
}

.modalTextBigger {
    display: flex;
    justify-content: space-around;
    font-family: RobotoMediumItalic;
    color: #707070;
    font-size: 20px;
}

.textConfig {
    color: #707070;
    font-size: 20px;
    font-family: RobotoMediumItalic;
}

.btnCircle {
    width: 30px;
    height: 30px;
    border: 1px solid #707070;
    border-radius: 50%;
    line-height: 30px;
    text-align: center;
    margin: auto;
    cursor: pointer;
    color: #707070;
}

.divRow {
    margin-top: 15px;
    margin-bottom: 15px;
}

.modalTextBigger {
    display: flex;
    justify-content: space-around;
    font-family: RobotoMediumItalic;
    color: #707070;
    font-size: 20px;
}

.buttonLeftText {
    padding-right: 0;
    text-align: right;
    padding-left: 0;
}

.button {
    height: 40px;
    border-radius: 50px;
    padding: 4px;
    cursor: pointer;
    width: 100%;
    box-shadow: 4px 4px 4px #00000029;
    border: 1px solid #83838b;
}

.startButtonSection {
    display: flex;
    justify-content: right;
    align-items: center;
}

.startButton {
    border-radius: 26px;
    padding: 13px 18px;
    font-size: 20px;
    color: #fff;
    background-color: #88addb;
    font-family: ComfortaBold;
    box-shadow: 4px 4px 4px #00000029;
    cursor: pointer;
    margin: 0px 10px;
    width: 8vw;
    margin-top: 1rem;

    height: 53px;
    min-width: 117px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circleRight {
    height: 30px;
    width: 30px;
    background-color: #bbbebe;
    border-radius: 50%;
    float: right;
}

.circleLeft {
    height: 30px;
    width: 30px;
    background-color: #88addb;
    border-radius: 50%;
    float: left;
}

.selected {
    border: 2px solid #88addb;
}
