.classList {
    width: 100%;
    overflow: hidden;
    height: 100vh;
    background-color: #f3f3f3;
}

.leftsection {
    padding: 8vh 50px;
    z-index: 10;
}

.leftSection {
    border: 2px solid #e9f3fb;
    box-shadow: 4px 4px 4px #00000029;
    padding: 10px 30px;
    border-radius: 21px;
    background-color: #f3f3f3;
}

.leftScroll {
    height: 78vh;
    overflow: hidden;
    padding-right: 30px;
}

.sessionblock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.crosssection {
    display: flex;
    align-items: center;
}

.italicPara {
    color: #707070;
    font-family: RobotoMediumItalic;
}

.heading {
    font-family: comfortaBold;
    margin: 0px;
    color: #707070;
    font-size: 35px;
    margin-bottom: 20px;
}

.stickerContainer {
    border: 1px solid #83838b;
    border-radius: 20px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.stickerScroll {
    height: 43vh;
    overflow: hidden;
    overflow-y: scroll;
}

.classList h2 {
    color: #707070;
    font-size: 25px;
}

.stickerRow {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    margin-top: 20px;
}

.sticker {
    padding: 10px 15px;
    border-radius: 26px;
    margin: auto;

    color: #707070;
    font-size: 22px;
    cursor: pointer;
}

.stickerWraper {
    text-align: center;
    width: 30%;
    border: 1px solid #83838b;
    border-radius: 26px;
    display: flex;
    align-items: center;
    height: 100px;
}

.stickerWraper img {
    height: 100%;
}

.stickerScroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}
.stickerScroll::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.btn {
    text-align: right;
    margin-top: 10px;
}

.nextBtn {
    background-color: #88addb;
    color: #fff;
    display: inline-block;
    box-shadow: 4px 4px 4px #00000029;
    border-radius: 26px;
    padding: 7px 25px;
    font-size: 22px;
    cursor: pointer;
}

.dateTime {
    font-family: RobotoMediumItalic;
    color: #707070;
    font-size: 17px;
    display: flex;
}

.date {
    margin: 0px;
}

.time {
    margin: 0px;
    margin-left: 20px;
}

.logoImg {
    position: fixed;
    width: 39vw;
    right: -9vw;
    top: -18vw;
}

.logoText,
.logoSubText {
    text-align: center;
    font-family: ComfortaBold;
    font-size: 2.8vw;
    line-height: 67px;
    letter-spacing: 0.3px;
    color: #ffffff;
    position: fixed;
    top: 1.2vw;
    right: 1.8vw;
    margin-bottom: 0;
}

.logoSubText {
    font-size: 2.2vw;
    top: 4.4vw;
    right: 5.5vw;
}

.rightTime {
    position: absolute;
    top: 8vw;
    right: 3.2vw;
    font-family: RobotoMedium;
    font-size: 30px;
    background-color: #e9f3fb;
    color: #707070;
    padding: 0.5vw 2.5vw;
    border-radius: 25px;
}

@media (max-width: 1200px) {
    .logoImg {
        position: fixed;
        width: 43vw;
        right: -10vw;
        top: -19vw;
    }

    .rightTime {
        top: 10vw;
        right: 6vmax;
        font-size: 3vw;
        border-radius: 4vmax;
        padding: 4px 20px;
    }
}

@media (max-width: 600px) {
    .logoImg {
        width: 50vw;
        right: -9vw;
        top: -18vw;
    }

    .rightTime {
        top: 16vw;
        left: 1vw;
        right: 9vw;
        font-size: 20px;
        padding: 0vh 3vw 0vh 2vw;
        border-radius: 4vw;
    }
}
