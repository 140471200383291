.container {
    display: grid;
    place-items: center;
    height: auto;
    padding: 150px 40px 20px;
}

.upgradeContainer {
    display: flex;
    flex-direction: column;
}

.heading {
    max-width: 600px;
    color: #88addb;
    font-size: 35px;
    padding: 0 10px;
    padding-left: 5px;
    font-family: ComfortaBold;
    margin-bottom: 25px;
}

.footertext {
    font-family: ComfortaBold;
    font-size: 21px;
    text-align: center;
    color: #13385c;
}

.otherLink {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    width: 100%;
    max-width: 600px;

    span {
        color: #88addb;
        font-size: 17px;
        padding: 8px;
        cursor: pointer;
    }
}

.paymentHeading {
    /* font-weight: bold; */
    font-size: 2rem;
    color: #002b4a;
    font-family: ComfortaBold;
}
.cardBox {
    border: 1px solid #83838b;
    border-radius: 21px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: auto;
    height: auto;
    box-shadow: -4px 4px 4px #00000029;
    /* border: 1px solid #83838b; */
    margin-top: 1%;
}
.ltd {
    font-family: RobotoMedium;
    font-size: 20px;
    margin-top: 10px;
    margin-left: 10px;
    padding-right: 20px;
}
.oneRem {
    margin-bottom: 1rem;
}
.edit {
    color: #88addb;
    font-size: 18px;
    font-family: Comforta;
    /* font-weight: bold; */
    cursor: pointer;
    padding-right: 10px;
}
.stripSVG {
    height: 50px;
    width: 107px;
}

@media screen and (max-width: 865px) {
    .container {
        padding: 90px 25px 20px;
    }
    .stripSVG {
        height: 35px;
        width: 90px;
    }
}
@media screen and (max-width: 567px) {
    .container {
        padding: 80px 20px 20px;
    }
    .stripSVG {
        height: 30px;
        width: 80px;
    }
    .cardBox {
        flex-wrap: wrap;
    }
    .heading {
        font-size: 30px;
        line-height: inherit;
    }
    .paymentHeading {
        font-size: 1.5rem;
    }
    .footertext {
        font-size: 16px;
    }
}

@media screen and (max-width: 443px) {
    .otherLink span {
        font-size: 13px;
    }
    .stripSVG {
        height: 28px;
        width: 50px;
    }
    .footertext {
        font-size: 15px;
    }
}
