.classList {
    width: 100%;
    overflow: hidden;
    height: 100vh;
    background-color: #f3f3f3;
}

.leftsection {
    padding: 8vh 50px;
    z-index: 10;
}

.leftSection {
    border: 2px solid #e9f3fb;
    box-shadow: 4px 4px 4px #00000029;
    padding: 30px;
    border-radius: 21px;
    background-color: #f3f3f3;
}

.leftScroll {
    height: 70vh;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 30px;
}

.sessionblock {
    display: flex;
    justify-content: space-between;
    padding: 3vh 0px;
    align-items: center;
}

.crosssection {
    display: flex;
    align-items: center;
}

.angleDownIcon {
    color: #83838b;
    height: 4vh;
    border-radius: 50%;
    padding: 1vh;
    cursor: pointer;
    z-index: 777;
    position: absolute;
    right: 0;
}

.option {
    position: absolute;
    right: 10px;
    top: 30px;
    background-color: #c8e8e5;
    border-radius: 10px;
    padding: 10px;
    z-index: 1;
    font-family: RobotoMedium;
}

.option p {
    margin: 0;
    color: #707070;
}

.coverImage {
    width: 100%;
    height: 100%;
}

.plusIcon {
    color: #83838b;
    background-color: #f3f3f3;
    height: 3vh;
    border-radius: 50%;
    padding: 1vh;
    cursor: pointer;
    box-shadow: 3px 3px 6px #00000029;
}

.publishedClass {
    display: flex;
    justify-content: space-between;
    padding: 3vh 0px;
    align-items: center;
}

.italicPara {
    font-family: RobotoMediumItalic;
    color: #707070;
}

.heading {
    font-family: comfortaBold;
    margin: 0px;
    color: #707070;
    font-size: 35px;
}

.text {
    font-family: RobotoMediumItalic;
    font-size: 18px;
    color: #707070;
    margin-left: 80px;
}

.classBoxWrapper {
    display: inline-block;
    cursor: pointer;
    margin-right: 60px;
}

.classBox {
    border: 1px solid #83838b;
    height: 185px;
    width: 290px;
    border-radius: 30px;
    box-shadow: 4px 4px 4px #00000029;
    z-index: 1;
    position: relative;
    overflow: hidden;
}

.freeBG {
    width: 7rem;
    position: absolute;
    top: -0.5px;
    left: -0.6px;
    z-index: 1;
}

.logoContainer {
    position: absolute;
    top: -2px;
    left: -1.5px;
    z-index: 70;
}

.studentLogoBg {
    width: 8rem;
    height: 3.5rem;
    max-width: 22.5rem;
    position: absolute;
    z-index: 71;
}

.courseText {
    z-index: 72;
    text-align: center;
    font-family: ComfortaBold;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0.3px;
    color: #88addb;
    position: absolute;
    margin: 0.8rem;
    cursor: pointer;
}

.scroll {
    overflow-x: auto;
    /* overflow-y: hidden; */
    display: flex;
}

.scroll::-webkit-scrollbar,
.leftScroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}
.scroll::-webkit-scrollbar-thumb,
.leftScroll::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.className {
    font-family: RobotoMedium;
    color: #707070;
    margin: 0px;
    margin-top: 10px;
}

.unpublishedsection {
    display: flex;
    justify-content: space-between;
    padding: 3vh 0px;
    align-items: baseline;
}

.dateTime {
    font-family: RobotoMediumItalic;
    color: #707070;
    font-size: 17px;
    display: flex;
}

.date {
    margin: 0px;
}

.time {
    margin: 0px;
    margin-left: 20px;
}

.logoImg {
    /* position: absolute;
    left: -210px;
    top: -250px; */
    position: fixed;
    width: 39vw;
    right: -9vw;
    top: -18vw;
}

.logoText,
.logoSubText {
    text-align: center;
    font-family: ComfortaBold;
    font-size: 2.8vw;
    line-height: 67px;
    letter-spacing: 0.3px;
    color: #ffffff;
    position: fixed;
    top: 1.2vw;
    right: 1.8vw;
    margin-bottom: 0;
}

.logoSubText {
    font-size: 2.2vw;
    top: 4.4vw;
    right: 5.9vw;
}

.rightTime {
    position: absolute;
    top: 8vw;
    right: 5.2vw;
    font-family: RobotoMedium;
    font-size: 30px;
    background-color: #e9f3fb;
    color: #707070;
    padding: 0.5vw 2.5vw;
    border-radius: 25px;
}

@media (max-width: 1200px) {
    .logoImg {
        position: fixed;
        width: 43vw;
        right: -10vw;
        top: -19vw;
    }

    .rightTime {
        top: 10vw;
        right: 4vmax;
        font-size: 3vw;
        border-radius: 4vmax;
        padding: 4px 20px;
    }
}

@media (max-width: 600px) {
    .logoImg {
        width: 50vw;
        right: -9vw;
        top: -18vw;
    }

    .rightTime {
        top: 16vw;
        left: 1vw;
        right: 9vw;
        font-size: 20px;
        padding: 0vh 3vw 0vh 2vw;
        border-radius: 4vw;
    }
}

.modal {
    position: absolute;
    top: 30%;
    left: 35%;
    background-color: #fff;
    padding: 40px 40px 20px 40px;
    width: 45%;
    text-align: center;
    border-radius: 26px;
    z-index: 2343;
}

.modalCross {
    color: #e36b2d;
    height: 4vh;
    border-radius: 50%;
    padding: 1vh;
    cursor: pointer;
    z-index: 777;
    position: absolute;
    right: 10px;
    top: 2px;
}

.modalText {
    margin: 0px;
    border: 1px solid gray;
    padding: 30px;
    border-radius: 26px;
}

.modalButton {
    background-color: #88addb;
    padding: 10px 30px;
    box-shadow: 4px 4px 4px #00000029;
    border-radius: 26px;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 0px;
    float: right;
}

.crossButton {
    cursor: pointer;
    margin-right: 5px;
}

.buttonWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 111px;
    width: auto;
    background-color: #c8e8e5;
    border-radius: 10px;
    font-family: RobotoMedium;
    z-index: 10;
}

.menuOption {
    font-family: RobotoMedium;
    padding: 5px 5px 0px;
}

.menuOption p {
    margin: 0;
    cursor: pointer;
    color: #707070;
}
