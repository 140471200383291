.savedFolderImagesContainer {
    font-family: "ComfortaBold";

    .savedFolderHeading {
        padding: 0 0.5rem;
        display: flex;
        margin-top: -0.5rem;
        gap: 1rem;
        align-items: center;

        span {
            cursor: pointer;
        }

        .selectedTab {
            color: #e36b2d;
            text-decoration: underline;
        }
    }

    .savedFolderContainer {
        max-height: 300px;
        width: 550px;
        overflow-y: scroll;
        display: grid;
        grid-template-columns: repeat(3, 3fr);
        align-items: center;
        justify-content: center;

        .imageContainer {
            margin: 0.25rem;
            border: 5px solid transparent;
            cursor: pointer;

            .videoContainer {
                position: relative;

                .videoControlIcon {
                    position: absolute;
                    color: white;
                    font-size: 3em;
                    background: black;
                    z-index: 99;
                    top: 25%;
                    left: 36%;
                    border-radius: 100%;
                }
            }

            img,
            video {
                height: 100%;
                max-height: 200px;
                width: 100%;
                display: flex;
            }

            .otherFileContainer {
                display: flex;
                flex-flow: column;
                align-items: center;
                padding: 0.5rem;

                .fileIconContainer {
                    svg {
                        font-size: 2.5em;
                        color: #bbbebe;
                    }
                }

                .fileName {
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    max-width: 145px;
                    margin-bottom: 0;
                }
            }
        }

        .selectedImageContainer {
            border: 5px solid #88addb;

            .otherFileContainer {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .savedFolderContainer::-webkit-scrollbar {
        width: 5px;
        background: #fff;
        border-radius: 50px;
        border: 1px solid rgb(131, 131, 139);
    }

    .savedFolderContainer::-webkit-scrollbar-thumb {
        background: #88addb;
        border-radius: 50px;
    }
}

@media screen and (max-width: 865px) {
    .savedFolderImagesContainer {
        width: 100%;

        .savedFolderContainer {
            grid-template-columns: repeat(1, 1fr);
            width: auto;

            .imageContainer {
                width: 165.66px;
                margin: 0.25rem auto;
            }
        }
    }
}
