.block {
    box-shadow: 3px 6px 6px #00000029;
    border: 1px solid #707070;
    border-radius: 24px;
    height: 26vh;
    max-width: 8vw;
    margin-bottom: 20px;
}

.blockMedia {
    position: absolute;
    top: 10px;
    right: 10px;
    max-width: 30vw;
    width: 30vw;
    height: 26vh;
}

.assets {
    width: 100%;
    height: 100%;
    border-radius: 24px;
}
