.container {
    background: #f7f2f2 0% 0% no-repeat padding-box;
    /* height: 261px; */
    /* height: 24vh; */
    height: 100%;
    border-radius: 24px;
    opacity: 1;
    overflow: hidden;
}
.container div {
    height: 100%;
}

.multiRowContainer {
    background: #f7f2f2 0% 0% no-repeat padding-box;
    /* height: 541px; */
    /* height: 50vh; */
    height: 100%;
    border-radius: 24px;
    opacity: 1;
    overflow: hidden;
    width: 100%;
}

.multiRowContainer div {
    height: 100%;
    width: 100%;
}
