.canvasWrapper {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 24px;
    overflow: hidden;
}

.whiteboardCanvas,
.whiteboardCanvasShape {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
}

.whiteboardCanvas {
    // background: #f7f2f2 0% 0% no-repeat padding-box;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 24px;
}

.lineBoxContainer {
    position: fixed;
    top: 1.3rem;
    left: 45.6%;
    width: 131px;
    padding: 0.5px 19px;
    background-color: #ebebeb;
    border-radius: 0 0 10px 10px;
    z-index: 1;
}

.lineContainer {
    padding: 5px 19px;
}

.line {
    margin-top: 0px;
    margin-bottom: 0px;
    border-top: 4px solid #707070;
}

.whiteboardCanvasShape {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 24px;
}

.whiteboard_control {
    position: fixed;
    top: 1.3rem;
    left: 23vw;
    display: flex;
    justify-content: center;
    background-color: #ebebeb;
    padding: 10px;
    box-shadow: 4px 4px 4px #00000029;
    border-radius: 0px 0px 21px 21px;
    opacity: 1;
    z-index: 8888;
    cursor: pointer;
}

.settingsBtnWrapper {
    position: relative;
    margin: auto;
    margin-right: 5px;
}

.settingBtn {
    cursor: pointer;
    border: 1px solid #707070;
    background-color: #fff;
    padding: 3px 7px;
    border-radius: 15px;
    margin-right: 10px;
    color: #707070;
}

.settingsModal {
    position: absolute;
    top: 48px;
    height: 300px;
}

.whiteboard_shape_wrapper {
    display: flex;
    align-items: center;
}

.whiteboard_shape_wrapper_selected img {
    border: 2px solid #e56e2c;
}

.whiteboard_shape_wrapper p {
    margin: 0px;
    margin-right: 10px;
    color: #707070;
    cursor: pointer;
}

#eraser {
    /* position: absolute;
  top: 15px;
  left: 15px; */
    cursor: pointer;
    margin-right: 10px;
}

.whiteboard_color_wrapper {
    display: flex;
    border: 1px solid #707070;
    border-radius: 20px;
    padding: 5px;
    margin-right: 10px;
    align-items: center;
    /* flex-direction: column;
  position: absolute;
  top: 70px;
  left: 15px; */
}

.whiteboard_color_selected {
    border: 2px solid #e56e2c;
    padding: 1px;
    margin-right: 5px;
    border-radius: 50%;
}

.whiteboard_color_unselected {
    margin-right: 5px;
}

.whiteboard_color {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
}

.whiteboard_width_wrapper {
    display: flex;
    border: 1px solid #707070;
    border-radius: 20px;
    padding: 5px;
    margin-right: 10px;
    align-items: center;
}

.whiteboard_width {
    margin-right: 10px;
    cursor: pointer;
    color: #707070;
}

.whiteboard_width_selected img {
    margin-right: 10px;
    cursor: pointer;
    color: #707070;
    border: 2px solid #e56e2c;
}

// .whiteboard_width:hover {
//   background-color: black;
// }

.clear_wrapper {
    position: relative;
}

.clear_option {
    position: absolute;
    background-color: rgb(200, 232, 229);
    width: 80px;
    padding: 5px;
    border-radius: 10px;
}

.clear_option p {
    margin-bottom: 0px;
    font-size: 14px;
    cursor: pointer;
}

.whiteboard_text {
    border: 1px dashed #707070;
    padding: 3px;
    margin: auto;
}

.whiteboard_text_highlighted {
    border: 1px dashed #e56e2c;
    padding: 3px;
    margin: auto;
}

.whiteboard_text p,
.whiteboard_text_highlighted p {
    margin: 0px;
    color: #707070;
}

.whiteboardBtn {
    p {
        cursor: pointer;
    }
}

.lineWidthWrapper {
    display: flex;
    border-radius: 20px;
    padding: 5px;
    margin-right: 10px;
}

.lineWidth {
    cursor: pointer;
    margin-right: 10px;
}

.lineColorWrapper {
    display: flex;
    padding: 5px;
    margin-right: 10px;
}

.lineColor {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
}

.shapeWrapper {
    p {
        cursor: pointer;
    }
}

.info {
    width: 100px;
    border: 1px solid black;
    position: absolute;
    height: 30px;
    transform: scale(1);
    /* top: 100px;
  left: 100px; */
    z-index: 100000;
}
