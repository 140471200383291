.ImageInputModalSelectedFilesContainer {
    display: flex;
    align-items: center;
    gap: 1rem;

    .navigationIcon,
    .removeIcon {
        cursor: pointer;
    }

    .zeroOpacity {
        opacity: 0;
        cursor: default;
    }

    .imagesListContainer {
        display: flex;
        align-items: center;
        gap: 1rem;

        .imageContainer {
            display: flex;
            flex-flow: column;
            align-items: center;

            span {
                align-self: flex-end;
                z-index: 1;
                margin-bottom: -1.5rem;
                margin-right: 0.3rem;
            }

            img,
            video {
                height: 100%;
                max-height: 200px;
                width: 100%;
                max-width: 160px;
            }

            .fileIconContainer,
            .audioFileIconContainer {
                font-size: 6em;
                color: #bbbebe;
                margin: 0 auto -1.2rem;
            }

            .audioFileIconContainer {
                position: relative;

                audio {
                    display: none;
                }

                .controlIcon {
                    position: absolute;
                    font-size: 0.4em;
                    left: 21%;
                    top: 40%;
                    z-index: 99;
                    color: white;
                    background: black;
                    border-radius: 100%;
                    cursor: pointer;
                }
            }

            .fileName {
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
                max-width: 140px;
                margin-bottom: 0;
            }
        }
    }
}
