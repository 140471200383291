.footerContainer {
    font-family: RobotoMediumItalic;
    color: #88addb;
    padding: 2rem 0 1rem;
    background-color: #e9f3fb;
    display: flex;
    align-items: center;
    justify-content: center;
}

.noSubscription {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}

.checkout {
    font-family: ComfortaBold;
    font-size: 14px;
    color: #dc531e;
    max-width: 250px;
}

.planTitle {
    font-family: ComfortaBold;
    font-weight: normal;
    font-size: 1.3rem;
    color: #002b4a;
    cursor: pointer;
}

.activePlanContainer {
    @extend .noSubscription;
}

.balance {
    font-size: 1rem;
    font-family: ComfortaBold;
    color: #707070;
    span {
        color: #e36b2d;
        font-size: 1.3rem;
        margin-bottom: -4px;
    }
}

.nextRefresh {
    font-size: 14px;
    font-family: ComfortaBold;
    color: #707070;
    cursor: pointer;
}
