.classList {
    width: 100%;
    overflow: hidden;
    background-color: #f3f3f3;
    height: 100vh;
}

.leftsection {
    padding: 7vh 50px;
    z-index: 200;
}

.leftSection {
    border: 2px solid #e9f3fb;
    box-shadow: 4px 4px 4px #00000029;
    padding: 10px;
    border-radius: 21px;
    background-color: #f3f3f3;
    height: 88vh;
}

.leftScroll {
    padding-right: 30px;
}

.dateTimeDuration {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.dateTimeDurationchild {
    display: flex;
    /* justify-content: space-between; */
    flex-direction: row;
    align-items: center;
    margin: 25px 2px 0px 0px;
    padding-right: 50px;
}

.sessionblock {
    display: flex;
    align-items: center;
}

.classheading {
    display: flex;
    justify-content: space-between;
    padding-top: 3vh;
    align-items: center;
}

.button {
    font-family: comfortaBold;
    background-color: #88addb;
    padding: 6px 18px;
    border-radius: 26px;
    cursor: pointer;
    color: #fff;
    font-size: 22px;
    box-shadow: 4px 4px 4px #00000029;
}

.heading {
    font-family: comfortaBold;
}

.scroll {
    overflow-x: auto;
    overflow-y: hidden;
    height: 100%;
}

.content {
    display: flex;
    padding-top: 20px;
    height: 100%;
}

.scrollbkp,
.contentbkp {
    transform: rotateX(180deg);
}

/* .feeInput {
  display: inline-block;
  border: 1px solid gray;
  border-radius: 15px;
} */

.feeInput {
    height: 2.7em;
    width: 6em;
    margin-top: 1em;
    margin: 0.3em;
    border: 1px solid #83838b;
    border-radius: 30px;
    font-size: 2em;
    box-shadow: 4px 4px 4px #00000029;
    background-color: #f3f3f3;
}

.feeInput::placeholder {
    color: #b3b6b6;
}

.freeSession {
    height: 2.7em;
    width: 6em;
    margin-top: 1em;
    margin: 0.3em;
    border: 1px solid #83838b;
    border-radius: 30px;
    box-shadow: 4px 4px 4px #00000029;
    background-color: #e3ebf7;
    font-size: 15px;
    color: #707070;
    font-family: RobotoMediumItalic;
    padding-left: 10px;
}

.freeSessionClose {
    background-color: #f4d0d0;
}

.courseLengthBtn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.courseLengthBtn p {
    font-size: 15px;
    color: #707070;
    font-family: RobotoMediumItalic;
    margin: 0px 3px;
}

.courseLengthBtn button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 2.7em;
    width: 9em;
    margin-top: 1em;
    margin: 0.3em;
    border: 1px solid #83838b;
    border-radius: 30px;
    box-shadow: 4px 4px 4px #00000029;
    background-color: #e3ebf7;
    font-size: 15px;
    color: #707070;
    font-family: RobotoMediumItalic;
    padding-left: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.feeInput {
    font-size: 15px;
    color: #707070;
    font-family: RobotoMediumItalic;
    padding-left: 10px;
}

.leftSectionChild {
    height: 84vh;
    overflow: auto;
    padding: 20px;
}

.scroll::-webkit-scrollbar,
.leftScroll::-webkit-scrollbar,
.leftSectionChild::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}

.scroll::-webkit-scrollbar-thumb,
.leftScroll::-webkit-scrollbar-thumb,
.leftSectionChild::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.className {
    font-family: RobotoMedium;
    color: #707070;
    font-size: 20px;
}

.className .tagsDescription {
    font-size: 14.6px;
    word-break: break-word;
    width: 176px;
}

.classblock {
    border: 1px solid #83838b;
    height: 160px;
    border-radius: 1vw;
    box-shadow: 4px 4px 4px #00000029;
    justify-content: center;
    width: 165px;
    margin-bottom: 20px;
    position: relative;
}

.incompleteClass {
    border: 4px solid #e36b2e;
}

@media (max-width: 768px) {
    .classblock {
        width: 15vw;
        max-height: 15vw;
    }
}

.customDropContainer {
    height: 100%;
    display: flex;
    align-items: center;
    align-self: center;
    align-content: center;
    width: 80%;
    margin: auto;
}

.dragAndDropIcon {
    cursor: grab;
}

.plusicon {
    color: #83838b;
    background-color: #f3f3f3;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    box-shadow: 3px 3px 6px #00000029;
}

.dragIcon {
    position: absolute;
    padding-top: 10px;
    font-size: 25px;
    cursor: pointer;
    left: 66px;
    color: #83838b;
    cursor: grab;
}

.minusicon {
    float: right;
    padding-top: 10px;
    padding-right: 10px;
    font-size: 25px;
    cursor: pointer;
    color: #83838b;
}

.option {
    position: absolute;
    left: 12px;
    top: 20px;
    background-color: #c8e8e5;
    border-radius: 10px;
    padding: 1px;
    font-size: 13px;
}

.option p {
    margin: 0;
    color: #707070;
    cursor: pointer;
}

.coverImage {
    width: 100%;
    height: 100%;
    border-radius: 2vw;
}

.modalCoverImage {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.italicPara {
    font-family: RobotoMediumItalic;
    color: #707070;
    font-size: 17px;
}

.importModal {
    background-color: #f3f3f3;
    box-shadow: 4px 4px 4px #00000029;
    position: fixed;
    top: 25%;
    left: 25%;
    z-index: 8798797979879;
    padding: 30px 35px;
    border-radius: 21px;
    height: 400px;
    width: 35vw;
}

.classModalText {
    color: #707070;
    font-family: RobotoMediumItalic;
    font-size: 20px;
}

.modalClassWrapper {
    border: 1px solid #83838b;
    height: 80%;
    padding: 20px;
    border-radius: 18px;
    padding-right: 10px;
}

.modalClassScroll {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 10px;
}

.modalLessonWrapper::-webkit-scrollbar,
.modalClassScroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}

.modalLessonWrapper::-webkit-scrollbar-thumb,
.modalClassScroll::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.modalClassBlock {
    height: 80px;
    border: 1px solid #83838b;
    border-radius: 21px;
    cursor: pointer;
}

.modalClassName {
    color: #707070;
    font-size: 17px;
    font-family: RobotoMediumItalic;
    text-align: center;
}

.modalLesson {
    height: 80px;
    border: 1px solid #83838b;
    border-radius: 21px;
    min-width: 6.3vw;
    margin-right: 10px;
    cursor: pointer;
}

.modalLessonName {
    color: #707070;
    font-size: 17px;
    font-family: RobotoMediumItalic;
    text-align: center;
    padding: 20px;
}
.modalLessonWrapper {
    display: flex;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 10px;
}

.classTitle {
    font-family: RobotoMediumItalic;
    color: #707070;
    border: none;
    margin-bottom: 10px;
    background-color: #f3f3f3;
    width: 100%;
}

.classDescription p {
    font-size: inherit !important;
}

.logoImg {
    position: fixed;
    width: 39vw;
    right: -9vw;
    top: -18vw;
}

.logoText,
.logoSubText {
    text-align: center;
    font-family: ComfortaBold;
    font-size: 2.8vw;
    line-height: 67px;
    letter-spacing: 0.3px;
    color: #ffffff;
    position: fixed;
    top: 1.2vw;
    right: 1.8vw;
    margin-bottom: 0;
}

.logoSubText {
    font-size: 2.2vw;
    top: 4.4vw;
    right: 5.9vw;
}

.rightTime {
    position: absolute;
    top: 8vw;
    right: 5.2vw;
    font-family: RobotoMedium;
    font-size: 30px;
    background-color: #e9f3fb;
    color: #707070;
    padding: 0.5vw 2.5vw;
    border-radius: 25px;
}

@media (max-width: 1200px) {
    .logoImg {
        position: fixed;
        width: 43vw;
        right: -10vw;
        top: -19vw;
    }

    .rightTime {
        top: 10vw;
        right: 6vmax;
        font-size: 3vw;
        border-radius: 4vmax;
        padding: 4px 20px;
    }
}

@media (max-width: 600px) {
    .logoImg {
        width: 50vw;
        right: -9vw;
        top: -18vw;
    }

    .rightTime {
        top: 16vw;
        left: 1vw;
        right: 9vw;
        font-size: 20px;
        padding: 0vh 3vw 0vh 2vw;
        border-radius: 4vw;
    }
}

.rightClassWrapper {
    margin-top: 250px;
    padding-right: 10px;
}

.rightclassblock {
    position: relative;
    border: 1px solid #83838b;
    height: 21vh;
    border-radius: 2vw;
    box-shadow: 4px 4px 4px #00000029;
    margin-top: 8vh;
}

.aboutClass {
    /* cursor: pointer; */
    height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.aboutClass input {
    cursor: pointer;
}

.aboutClass::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}
.aboutClass::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.classModal {
    background-color: #f3f3f3;
    box-shadow: 4px 4px 4px #00000029;
    position: fixed;
    top: 30%;
    left: 25%;
    z-index: 8798797979879;
    padding: 30px 35px;
    border-radius: 21px;
    width: 40vw;
}

.classModal2 {
    background-color: #f3f3f3;
    box-shadow: 4px 4px 4px #00000029;
    position: fixed;
    top: 30%;
    left: 25%;
    z-index: 8798797979879;
    padding: 30px 35px;
    border-radius: 21px;
    width: 40vw;
    height: 30vw;
}

.savedFolderBtn {
    background-color: #88addb;
    font-size: 20px;
    padding: 15px 25px;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    margin-bottom: 0;

    height: 53px;
    min-width: 117px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.assets {
    width: 100%;
    height: 100%;
}

.crossIcon {
    position: absolute;
    right: 20px;
    top: 13px;
    color: #e36b2d;
    cursor: pointer;
}

.classWrapper {
    border: 1px solid #707070;
    padding: 25px 10px;
    border-radius: 18px;
    color: #707070;
    width: 35vw;
    height: 20vw;
    padding-right: 10px;
}

.classWrapperEditor {
    font-family: RobotoMedium;
}

.chat_form__message_box {
    border: 1px solid #707070;
    padding: 0.7rem;
    display: block;
    width: 100%;
    overflow: hidden;
    resize: both;
    min-height: 7rem;
    line-height: 20px;
    margin: 1rem 0;
    background-color: transparent;
    outline: none;
}

.chat_form__message_box[contentEditable]:empty::before {
    content: "";
    color: #bbbebe;
    cursor: text;
}

.classScroll {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 10px;
}

.classScroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}
.classScroll::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.classWrapper h5 {
    font-size: 17px;
    font-family: comfortaBold;
    margin-top: 20px;
    margin-bottom: 0px;
}

.description {
    border-bottom: 1px solid #707070;
    font-family: RobotoMediumItalic;
    color: #707070;
}

.mde :global(.CodeMirror) {
    background: transparent;
    border: none !important;
    height: 100px !important;
}

.saveBtn {
    margin-top: 15px;
    background-color: #88addb;
    display: inline-block;
    float: right;
    color: #f3f3f3;
    box-shadow: 4px 4px 4px #00000029;
    border-radius: 26px;
    font-family: comfortaBold;
    font-size: 20px;
    padding: 10px 25px;
    margin-bottom: 0px;
    cursor: pointer;
}

.uploadMediaModal {
    text-align: center;
    margin-bottom: 10px;
}

.uploadArea {
    border: 1px solid #707070;
    padding: 28px 17px;
    border-radius: 18px;
    width: 35vw;
    height: 20vw;
    margin-bottom: 20px;
}

.uploadAreaStripe {
    border: 1px solid #707070;
    padding: 25px 10px;
    border-radius: 18px;
    width: 25rem;
    height: fit-content;
    margin-bottom: 20px;
}
.modalHeading {
    color: #707070;
    font-family: ComfortaBold;
    font-size: 18px;
}

.uploadArea p {
    color: #707070;
    font-family: ComfortaBold;
    font-size: 13px;
}

.mediaType {
    display: flex;
}

.mediaType p {
    margin-right: 20px;
    cursor: default;
    color: #707070;
    font-family: ComfortaBold;
}

.mediaType p:hover {
    border-bottom: 1px solid #707070;
}

.mediaContent {
    height: 96%;
    /* padding-right: 30px; */
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    padding-right: 15px;
}

.mediaContent::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}

.mediaContent::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.mediaBlockModal {
    width: 80px;
    /* height: 5vw; */
    border: 1px solid #707070;
    margin-bottom: 20px;
    background-color: #fff;
    margin-right: 15px;
    cursor: pointer;
    height: 80px;
    object-fit: cover;
}

pre p {
    margin-bottom: 0px;
    font-family: RobotoMediumItalic;
    font-size: 17px;
}

.alertText {
    font-family: RobotoItalic;
    font-size: 12px;
    text-align: center;
    color: #707070;
}

.uploadBtn {
    display: inline-flex;
    justify-content: space-between;
    border: 1px solid #83838b;
    box-shadow: 4px 4px 4px #00000029;
    padding: 7px 10px;
    border-radius: 26px;
    cursor: pointer;
}

.toolText {
    margin: 0px;
    margin-right: 5px;
}

.upperText {
    text-align: center;
}

.modalGalleryWrapper {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    margin-top: 20px;
    position: relative;
    padding: 0px 20px;
}

/* .modalGalleryWrapper::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: #fff;
  border: 1px solid rgb(131, 131, 139);
  border-radius: 50px;
}
.modalGalleryWrapper::-webkit-scrollbar-thumb {
  background: #88addb;
  border-radius: 50px;
} */

.modalImageGallery {
    width: 100%;
    height: 100%;
    /* border-radius: 1vw;
  margin: 20px 10px; */
}

.modalImageWrapper {
    position: relative;
    margin: auto;
    width: 130px;
    height: 100px;
    border-radius: 26px;
    border: 1px solid #83838b;
    overflow: hidden;
}

video {
    outline: none;
}

.modalCross {
    background-color: #f3f3f3;
    position: absolute;
    right: 10px;
    top: 10px;
    color: #83838b;
    cursor: pointer;
    border-radius: 5px;
}

.lowerText {
    font-family: RobotoItalic;
    font-size: 13px;
    margin-top: 30px;
    text-align: center;
}

.uploadIcon {
    color: #fff;
    height: 25px;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    background-color: #88addb;
}

[contentEditable="true"]:empty:not(:focus):before {
    content: attr(data-text);
}

@media (max-width: 600px) {
    .scroll {
        height: 150%;
    }
    .minusicon {
        padding-top: 0px;
    }
}

.plusiconContainer {
    display: flex;
    align-items: center;
}

.uploadModal {
    background-color: #ffffff;
    box-shadow: 4px 4px 4px #00000029;
    position: fixed;
    top: 15%;
    left: 25%;
    z-index: 8798797979879;
    padding: 30px 35px;
    border-radius: 21px;
    width: 30rem;
}

.ageGroupRow {
    /* display: flex;
    align-items: center; */
    width: 100%;
}
.btnNavContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 5vh;
    padding-right: 30px;
    height: 30vh;
    position: relative;
}
.btnNavContainer::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}
.btnNavContainer::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}
.btnNav {
    border: 1px solid #cbcbf2;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 4px -1px 2px #00000029;
    width: 165px;
    color: #83838b;
    padding: 5px;
    padding-bottom: 0px;
}
.updateBtnNav {
    border-bottom: 4px solid #f3f3f3;
    color: #88addb;
    z-index: 1;
}
.btnNavbar {
    display: flex;
    flex-direction: column;
}
.slotContainer {
    position: absolute;
    margin: 30px 0px;
    width: 900px;
    height: auto;
    padding: 0px 20px 20px 20px;
    box-shadow: 4px 4px 4px #00000029;
    border: 1px solid #cbcbf2;
    /* border-top: 0px; */
    border-radius: 20px;
    border-top-left-radius: 0px;
    border-top-right-radius: 20px;
}

.moneyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.nameAndDescription {
    display: flex;
    flex-direction: row;
    border: 1px solid #83838b;
    border-radius: 30px;
    justify-content: space-evenly;
    align-items: center;
    padding: 8px;
}

.nameAndDescription p {
    font-size: 14px;
    margin: 0px;
    padding: 5px 0px;
    font-family: "RobotoMediumItalic";
}

.dropicon {
    background-color: #88addb;
    color: #fff;
    height: 30px;
    border-radius: 50%;
    width: 30px;
    cursor: pointer;
}
