.carousel img {
    width: 100%;
}

.carousel ul.control-dots {
    display: none;
}

.sliderImage {
    width: 100%;
    height: 50px;
    border-radius: 5px;
}
