.block {
    box-shadow: 3px 6px 6px #00000029;
    border: 1px solid #707070;
    border-radius: 24px;
    height: 15vh;
    max-width: 8vw;
    width: 5vw;
    margin-bottom: 20px;
}

.audio {
    width: 100%;
    height: 100%;
    display: none;
}

.video {
    object-fit: contain;
}

.assets {
    width: 100%;
    height: 100%;
    border-radius: 24px;
}

.image {
    object-fit: contain;
}

.participantsList {
    height: 78vh;
    width: 10vw;
    position: absolute;
    right: 0;
    top: 10vh;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
}

.lessParticipantBoxesCol {
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 10px;
}

.moreParticipantBoxesCol {
    position: relative;
    top: 30px;
}
