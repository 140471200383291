.originalButton {
    background-color: #88addb;
    padding: 8px 18px 7px;
    border-radius: 26px;
    cursor: pointer;
    color: #fff;
    width: fit-content;
    font-size: 1.5rem;
    font-family: ComfortaBold;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.16);
}

.disablebutton {
    background-color: gray;
    background: gray;
}

@media screen and (max-width: "467") {
    .originalButton {
        font-size: 1rem !important;
    }
}
