.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #E36B2D;
  background-color: #fff;
  border: none;
  border-color: none;
}

.nav-tabs {
  border-bottom: none;
  /* font-family: Comforta; */
  font-weight: bold !important;
  font-size: 20px;
}

.nav-tabs .nav-link:hover{
  border: none;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  padding-right: 52px;
}
