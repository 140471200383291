.logoDiv {
    position: fixed;
    left: -11vw;
    top: -17vw;
    z-index: 1;
    cursor: pointer;
}

.logoContainer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}

.studentLogoBg {
    width: 22.5rem;
    position: fixed;
}

.logoText,
.logoSubText {
    text-align: center;
    font-family: ComfortaBold;
    font-size: 37px;
    line-height: 67px;
    letter-spacing: 0.3px;
    color: #ffffff;
    position: fixed;
    top: 2.3rem;
    left: 3rem;
    margin-bottom: 0;
}

.logoSubText {
    font-size: 21px;
    top: 4.9rem;
    left: 3.6rem;
}

.logoImg {
    width: 38vw;
}

.parentWrapper {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
}

.outerBlock {
    padding: 20px;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
}

.wrapper {
    border: 1px solid #707070;
    border-radius: 25px;
    box-shadow: -4px 4px 4px #00000029;
    height: 93vh;
    position: relative;
    background-color: #ffffff;
    padding: 10vh 2vw 10vh 5vw;
}

.confirmButton {
    padding: 13px 18px;
    font-size: 21px;
    color: #fff;
    background-color: #13385c;
    font-family: ComfortaBold;
    cursor: pointer;
    width: 16vw;
    justify-content: center;
    display: flex;
    box-shadow: 4px 4px 4px #00000029;
    border-radius: 32px;
    /* position: absolute;
    right: 1vw;
    bottom: 1vh; */
    line-height: 24px;
    position: absolute;
    right: 1vw;
    top: calc(110px + 2vh);
}

.content {
    top: 30%;
    position: absolute;
}

.description {
    font-family: RobotoMediumItalic;
    letter-spacing: 0.11px;
    color: #707070;
    font-size: 22px;
    line-height: 25px;
    margin-bottom: 7vh;
}

.time {
    font-family: RobotoMediumItalic;
    letter-spacing: 0.15px;
    color: #e36b2d;
    font-size: 30px;
    line-height: 35px;
}

.timeText {
    margin: 0;
}

.normalText {
    font-family: RobotoMediumItalic;
    letter-spacing: 0.11px;
    color: #707070;
    font-size: 22px;
    line-height: 25px;
}

.starsImg {
    width: 25vw;
    position: fixed;
    right: 1.3rem;
    top: 1.3rem;
    z-index: 1;
}

.price {
    font-size: 99px;
    color: #88addb;
    font-family: ComfortaBold;
    text-align: right;
    margin: 0;
    line-height: 110px;
    margin-top: 3vh;
    /* position: absolute; */
    /* right: 2vw; */
    position: absolute;
    right: 2vw;
    top: 0;
}

.classDetails {
    box-shadow: -4px 4px 4px #00000029;
    border-radius: 21px;
    background-color: #e9f3fb;
    padding: 3vh 2vw;
    z-index: 1;
    position: relative;
}

.heading {
    color: #88addb;
    font-family: ComfortaBold;
    font-size: 35px;
    line-height: 39px;
}

.paymentDetailsWrapper {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    border-radius: 21px;
    height: 48vh;
    z-index: 1;
    position: relative;
    background: #ffff;
    padding: 4vh 2vw;
}

.upperRow {
    position: relative;
    top: -5vh;
}

.descriptionText {
    margin: 1vh;
}

.paymentDetailsBottomText {
    position: absolute;
    bottom: 0;
    margin-top: 0;
    margin-bottom: 1rem;
}
