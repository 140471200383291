.spotlightOuterContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.spotlightContainer {
    background-color: #f3f3f3;
    z-index: 99999;
    border-radius: 24px;
    box-shadow: 4px 4px 4px #00000029;
}

.spotlightHeaderContainer {
    padding: 10px 15px;
    text-align: right;
}

.crossIcon {
    color: #e36b2d;
    cursor: pointer;
}

.spotlightParticipantContainer {
    height: 75vh;
}
