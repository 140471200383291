.checkBtn {
    width: 25px;
    height: 25px;
    border: 1px solid #707070;
    border-radius: 5px;
    margin-right: 1rem;
    cursor: pointer;
}

.frequencyBox {
    margin-top: 6%;
    margin-left: 1%;
    margin-bottom: 2%;
    padding-top: 1%;
    border: 1px solid #83838b;
    border-radius: 21px;
    box-shadow: -4px 4px 4px #00000029;
    background-color: #fff;
    padding: 28px 30px;
}

.studentScroll {
    overflow-x: auto;
    overflow-y: auto;
    height: 65vh;
    width: 100%;
}

.studentScroll::-webkit-scrollbar {
    height: 5px;
    width: 7px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}

.studentScroll::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.updateButton {
    float: right;
}
.updateButton img {
    width: 130px;
}

@media (max-width: 500px) {
    .updateButton {
        float: left !important;
    }
    .updateButton img {
        height: 70px;
        width: 120px;
    }
}

.oneRem {
    margin-bottom: 1rem !important;
    font-size: 18px;
    line-height: 20px;
    color: #13385c;
    font-weight: 700;
}

.uploadModalImage {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8798797979879;
    width: 100vw;
    height: 100vh;
    display: flex;
}

.uploadModalContainer {
    background-color: #f3f3f3;
    border-radius: 21px;
    box-shadow: 4px 4px 4px #00000029;
    padding: 30px 35px;
    margin: auto;
    max-width: 80vw;
    max-height: 80vh;
}

.crossIconImageModal {
    position: relative;
    left: 100%;
    color: #e36b2d;
    cursor: pointer;
    top: -2%;
}

.emailInput {
    margin-top: 20px;
}

.previewImage__container {
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    width: 26rem;
}

.previewImage__container img {
    width: 30%;
    cursor: pointer;
}

.inputBox {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    border-radius: 21px;
    width: 100%;
    margin-bottom: 2%;
    margin-top: 2%;
    margin-right: 31px;
    padding-right: 2%;
    padding-left: 15px;
    height: 44px;
}

.inputBox::-webkit-input-placeholder {
    font-size: 20px;
    margin-left: 20px;
}
