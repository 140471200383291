.ImageInputModalContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    .ImageInputModalBody {
        border: 0;
        background-color: #f3f3f3;
        box-shadow: 4px 4px 4px #00000029;
        padding: 15px 35px 30px;
        border-radius: 21px;
        min-width: 511px;
        min-height: 407px;

        display: flex;
        flex-flow: column;
        justify-content: center;

        .crossIconContainer {
            margin-bottom: 0.5rem;
            align-self: flex-end;

            .crossIcon {
                color: #e36b2d;
                cursor: pointer;
            }
        }

        .modalWrapper {
            border: 1px solid #707070;
            border-radius: 18px;
            color: #707070;
            padding: 25px 10px 20px;

            display: flex;
            // justify-content: center;
            align-items: center;
            flex-flow: column;
            flex: 1;

            * {
                font-family: "ComfortaBold";
            }

            .titleText {
                width: 61%;
                text-align: center;
            }

            .uploadFromComputerButton,
            .uploadFromSavedFolderButton {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #83838b;
                box-shadow: 4px 4px 4px #00000029;
                padding: 7px 10px;
                border-radius: 26px;
                cursor: pointer;
                margin-bottom: 10px;

                p {
                    margin: 0 5px 0 0;
                }

                span {
                    padding: 7.5px;
                    background-color: #88addb;
                    color: #fff;
                    border-radius: 50%;
                    cursor: pointer;
                    display: flex;

                    .uploadIcon {
                        margin: auto;
                        width: 15px;
                        height: 15px;
                    }
                }

                input {
                    display: none;
                }
            }

            .uploadFromSavedFolderButton {
                p {
                    margin: 0 0 0 5px;
                }
            }
        }

        .readMoreText {
            font-size: 13px;
            text-align: center;
            margin: 0.5rem 0 0;

            span {
                color: #88addb;
                cursor: pointer;
            }
        }

        .finishButton,
        .backButton {
            background-color: #88addb;
            width: fit-content;
            align-self: flex-end;
            color: #f3f3f3;
            box-shadow: 4px 4px 4px #00000029;
            border-radius: 26px;
            font-family: comfortaBold;
            font-size: 20px;
            padding: 10px 25px;
            margin-top: 1rem;
            cursor: pointer;
        }

        .backButton {
            align-self: flex-start;
        }

        .savedFolderNavigationButtonContainer {
            display: flex;
            justify-content: space-between;
        }
    }

    .ImageInputModalBody:focus {
        outline: none;
    }
}

@media screen and (max-width: 865px) {
    .ImageInputModalContainer {
        .ImageInputModalBody {
            min-width: 100%;
        }
    }
}
