.container {
    margin-bottom: 8rem;
}

.paymentTable {
    font-family: RobotoMedium;
    border: none;
    border-spacing: 15px;
    border-collapse: separate;
    border-spacing: 0 0.5em;
}

.heading {
    /* font-weight: bold; */
    font-size: 35px;
    height: 47px;
    /* width: 278px; */
    opacity: 1;
    color: #002b4a;
    font-family: ComfortaBold;
}

.cardBox {
    border: 1px solid #83838b;
    border-radius: 21px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 30rem;
    height: 4em;
    box-shadow: -4px 4px 4px #00000029;
    /* border: 1px solid #83838b; */
    margin-top: 1%;
}

.cardBoxStudent {
    border: 1px solid #83838b;
    border-radius: 21px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 4em;
    box-shadow: -4px 4px 4px #00000029;
    /* border: 1px solid #83838b; */
    margin-top: 1%;
    margin-left: 1%;
    width: 23rem;
    padding-left: 1rem;
}

.tableHead {
    font-family: RobotoMedium;
    border: none;
    margin-top: 10px;
    /* background-color: aqua; */
    color: #002b4a;
}

.tableBody {
    opacity: 1;
    background-color: #e9f3fb;
    margin-bottom: 10em;
    padding-top: 50em;
    border-bottom: hidden;
    cursor: pointer;
    transition: all 6s ease;
}
.tableBodyInner {
    opacity: 1;
    margin-bottom: 10em;
    padding-top: 50em;
    border: hidden;
    cursor: pointer;
    transition: all 6s ease;
    text-align: left;
}

.tableBodyMain {
    margin-top: 50em;
    /* transition-duration: 5s; */
    /* transition: all 0.2s ease-in-out; */
    /* transition: max-height 3s, padding 0.6s; */
    color: #707070;
    transition: all 6s ease;
}

.classDetail {
    position: relative;
    left: 103%;
    /* transition: all 5s ease-in-out; */
    /* transition: max-height 3s, padding 0.6s; */
    transition: all 6s ease;
}

.ltd {
    font-family: RobotoMedium;
    font-size: 20px;
    margin-top: 3%;
    margin-left: 5%;
}

.edit {
    color: #88addb;
    font-size: 18px;
    font-family: Comforta;
    /* font-weight: bold; */
    cursor: pointer;
}

.thumbVertical {
    color: red !important;
    background-color: red;
    width: 20px;
}
@media (min-width: 320px) {
    .addAccount {
        font-family: RobotoMedium;
        font-size: 20px;
        margin-top: 2em;
    }
}

.price {
    font-size: 26px;
    color: #88addb;
    font-family: ComfortaBold;
    text-align: right;
    line-height: 30px;
}

.addAccount {
    font-family: RobotoMedium;
    font-size: 20px;
}

.trackVertical {
    color: red !important;
    background-color: red;
    width: 20px;
}

.studentblock {
    /* height: 40vh; */
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* background-color: blue; */
}

.studentScroll {
    overflow-x: auto;
    overflow-y: auto;
    height: 65vh;
    width: 100%;
    /* background-color: blue; */
}

.studentScroll::-webkit-scrollbar {
    height: 5px;
    width: 7px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}

.studentScroll::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.innerContainer {
    width: 94%;
    /* height: 100vh; */
}

@media (max-width: 768px) {
    .innerContainer {
        width: 50%;
        height: 100vh;
    }
}

.refundNames {
    margin-left: 40%;
    width: 200%;
}

.oneRem {
    margin-bottom: 1rem;
}

.errors {
    color: #e36b2d;
    font-size: 18px;
    font-weight: bold;
    font-family: ComfortaBold;
    margin-top: 1rem;
}

.termsStyle {
    color: #88addb;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    font-family: ComfortaBold;
    width: fit-content;
}

.errorList {
    list-style-type: none;
}

ul li:before {
    content: "";
    display: inline-block;
    height: 19px;
    width: 35px;
    background-image: url("../../../assets/images/Close_button.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: -35px;
}
