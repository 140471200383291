.row {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #f3f3f3;
}

.leftsection {
    padding: 8vh 0px 8vh 50px;
    z-index: 1;
    height: 100vh;
}

.crossImg {
    cursor: pointer;
}

.italicPara {
    font-family: RobotoMediumItalic;
    color: #707070;
    margin-bottom: -3px;
}

.scroll {
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
}

.scroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}
.scroll::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.customise {
    box-shadow: 4px 4px 4px #00000029;
    border: 2px solid #e9f3fb;
    border-radius: 0px 21px 21px 21px;
    /* height: 48vh; */
    padding: 10px;
    margin-top: -2px;
}

.customiseScroll {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 5px;
}

.customiseScroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}
.customiseScroll::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.gameTabs {
    display: flex;
    overflow: hidden;
}

.gameTab {
    margin-right: 2px;
    border: 2px solid #e9f3fb;
    border-bottom: none;
    padding: 10px 20px 0px 20px;
    border-radius: 21px 21px 0px 0px;
    margin-bottom: -2px;
    cursor: pointer;
    color: #707070;
    font-size: 18px;
    font-family: RobotoMediumItalic;
    box-shadow: 4px 4px 4px #00000029;
}

.textConfig {
    color: #707070;
    font-size: 20px;
    font-family: RobotoMediumItalic;
}

.imageWrapper {
    display: flex;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 10px;
}

.imageWrapper::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}
.imageWrapper::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.imageSection {
    box-shadow: 4px 4px 4px #00000029;
    border: 1px solid #83838b;
    border-radius: 21px;
    height: 71px;
    min-width: 100px;
    margin-right: 20px;
    cursor: pointer;
    margin-bottom: 10px;
    max-width: 100px;
    overflow: hidden;
}

.imageSection.selectedImage {
    border: 3px solid #88addb;
}

.selectedImage {
    border: 3px solid #88addb;
}

.modalImageWrapperContainer {
    margin: auto;
}

.modalImageWrapper {
    position: relative;
    width: 130px;
    height: 100px;
    border-radius: 26px;
    border: 1px solid #83838b;
    overflow: hidden;
}
.modalImageGallery {
    width: 100%;
    height: 100%;
}

.modalFileName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
    margin: auto;
}

.modalGalleryWrapper {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    margin-top: 20px;
    position: relative;
    padding: 0px 20px;
}

.modalCross {
    background-color: #f3f3f3;
    position: absolute;
    right: 10px;
    top: 10px;
    color: #83838b;
    cursor: pointer;
    border-radius: 5px;
}

.btnCircle {
    width: 30px;
    height: 30px;
    border: 1px solid #707070;
    border-radius: 50%;
    line-height: 30px;
    text-align: center;
    margin: auto;
    cursor: pointer;
    color: #707070;
}

.activityPreview {
    box-shadow: 4px 4px 4px #00000029;
    border: 1px solid #83838b;
    /* height: 364px; */
    border-radius: 26px;
}

.btnWrapper {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.button {
    border: 1px solid #83838b;
    height: 40px;
    border-radius: 50px;
    padding: 4px;
    cursor: pointer;
    width: 100px;
}

.circleRight {
    height: 30px;
    width: 30px;
    background-color: #bbbebe;
    border-radius: 50%;
    float: right;
}

.circleLeft {
    height: 30px;
    width: 30px;
    background-color: #88addb;
    border-radius: 50%;
    float: left;
}

.downAngleIcon {
    background-color: #bbbebe;
    color: #fff;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
}

.iconsection {
    border: 2px solid #dfe1e1;
    border-bottom: none;
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;
    background-color: #f3f3f3;
    cursor: pointer;
    box-shadow: 0px -3px 8px -3px rgba(0, 0, 0, 0.16);
}

.heading {
    font-family: ComfortaBold;
    font-size: 25px;
    color: #707070;
    margin-left: 10px;
}

audio:focus {
    outline: none;
}

.editImg {
    margin-left: 5px;
}

.backBtn {
    background-color: #c8e8e5;
    font-size: 17px;
    color: #707070;
    box-shadow: 4px 4px 4px #00000029;
    border-radius: 26px;
    margin-right: 10px;
    cursor: pointer;
    margin-bottom: 0;

    height: 40px;
    min-width: 117px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sceneDropdown {
    display: flex;
    border: 1px solid #83838b;
    border-radius: 20px;
    padding: 5px;
    color: #707070;
    position: relative;
}

.sceneBlock {
    position: absolute;
    top: 35px;
    background-color: #c8e8e5;
    z-index: 868;
    width: 90%;
    padding: 5px;
}

.rightHeading {
    font-family: RobotoMediumItalic;
    font-size: 20px;
    color: #707070;
    margin-bottom: 30px;
}

.configureImg {
    width: 100%;
    overflow: hidden;
    height: 100%;
    border-radius: 20px;
}

.leftSection {
    border: 2px solid #dfe1e1;
    box-shadow: 4px 4px 4px #00000029;
    padding: 0px 20px;
    border-radius: 21px;
    border-top-left-radius: 0px;
    height: 80vh;
    background-color: #f3f3f3;
    margin-top: -2px;
    overflow-y: auto;
}

.leftSection::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}

.leftSection::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.leftScroll {
    position: relative;
    height: 30vh;
    overflow: hidden;
    overflow-y: scroll;
    padding: 20px;
}

.sessionblock {
    position: relative;
    display: flex;
    padding: 2vh 10px;
    align-items: center;
    justify-content: space-between;
}

.crosssection {
    display: flex;
    align-items: center;
}

.playicon {
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
}

.leftScroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}
.leftScroll::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.lessonWrapper {
    display: inline-block;
    margin-right: 40px;
    height: 100%;
}

.lessionSection {
    display: flex;
}

.lessonBlock {
    display: inline-block;
    margin-right: 40px;
}

.classblock {
    border: 1px solid #83838b;
    height: 160px;
    border-radius: 1vw;
    box-shadow: 4px 4px 4px #00000029;
    justify-content: center;
    width: 165px;
    margin-bottom: 20px;
    position: relative;
}

.content {
    display: flex;
    padding-top: 0px;
    height: 100%;
}

.rightSection {
    text-align: center;
}

.plusicon {
    color: gray;
    background-color: #dfe1e1;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    padding: 5px;
    margin-top: 25px;
    cursor: pointer;
}

.plusTabIcon {
    height: 40px;
    background-color: #dfe1e1;
    box-shadow: 3px 3px 6px #00000029;
    padding: 9px;
    border-radius: 50%;
    color: #83838b;
    cursor: pointer;
}

.uploadModal {
    background-color: #f3f3f3;
    box-shadow: 4px 4px 4px #00000029;
    position: fixed;
    top: 30%;
    left: 25%;
    z-index: 8798797979879;
    padding: 30px 35px;
    border-radius: 21px;
    width: 40vw;
}

.studentblock {
    border: 1px solid rgb(131, 131, 139);
    height: 30vh;
    border-radius: 20px;
    padding: 10px;
    overflow: hidden;
}

.studentScroll {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
}

.studentScroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}

.studentScroll::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.detailsContainer {
    position: relative;
    height: 30vh;
    overflow: hidden;
    overflow-y: scroll;
    padding: 20px;
}

.highlight {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    border: 10px solid #88addb !important;
    border-radius: 31px;
}

.ColLayoutContainer {
    width: 290px;
}

@media (max-width: 1185px) {
    .ColLayoutContainer {
        min-width: 290px;
    }
}

.imgAnswerModal {
    position: absolute;
    top: 0px;
    background-color: #fff;
    z-index: 5;
    left: 26%;
    padding: 30px;
    border-radius: 25px;
    box-shadow: 4px 4px 4px #00000029;
    width: 50%;
}

.imgsection {
    cursor: pointer;
    border: 10px solid transparent;
}

.tabFocus {
    color: #88addb;
    box-shadow: none;
    border-bottom: none;
    background-color: #f3f3f3;
}

.finishModal {
    position: absolute;
    top: 10px;
    right: 10px;
}

.finishModal p {
    font-family: RobotoItalic;
    font-size: 12px;
    text-align: center;
}

.modalTopSection {
    display: flex;
    justify-content: space-between;
}

.modalTopSection p {
    font-family: RobotoItalic;
    font-size: 12px;
    text-align: center;
}
.uploadIcon {
    color: #fff;
    height: 25px;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    background-color: #88addb;
}

.uploadArea {
    border: 1px solid #707070;
    padding: 25px 10px;
    border-radius: 18px;
    width: 35vw;
    height: fit-content;
    margin-bottom: 20px;
}

.uploadArea p {
    color: #707070;
    font-family: ComfortaBold;
}

.backBtnModal {
    background-color: #e36b2d;
    font-size: 18px;
    display: inline-block;
    box-shadow: 4px 4px 4px #00000029;
    padding: 4px 16px;
    border-radius: 26px;
    margin-top: 20px;
    font-family: ComfortaBold;
    cursor: pointer;
    margin-bottom: 0;

    height: 53px;
    min-width: 117px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backBtnModal {
    color: #fff;
}

.mediaType {
    display: flex;
}

.mediaType p {
    margin-right: 20px;
    cursor: default;
    color: #707070;
    font-family: ComfortaBold;
}

.mediaType p:hover {
    border-bottom: 1px solid #707070;
}

.mediaContent {
    height: 100%;
    /* padding-right: 30px; */
    overflow: hidden;
    overflow-y: auto;
    display: flex;
}

.mediaContent::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}

.mediaContent::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.mediaBlockModal {
    width: 8vw;
    height: 5vw;
    border: 1px solid #707070;
    margin-bottom: 20px;
    background-color: #fff;
    margin-right: 15px;
    cursor: pointer;
}

.assets {
    width: 100%;
    height: 100%;
}

.savedFolderBtn {
    background-color: #88addb;
    font-size: 20px;
    padding: 15px 25px;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    margin-bottom: 0;

    height: 53px;
    min-width: 117px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dragText {
    text-align: center;
}

.uploadMediaModal {
    text-align: center;
}

.uploadBtn {
    display: inline-flex;
    justify-content: space-between;
    border: 1px solid #83838b;
    box-shadow: 4px 4px 4px #00000029;
    padding: 7px 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 26px;
    cursor: pointer;
}

.toolText {
    margin: 0px;
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.finishBtn {
    background-color: #88addb;
    color: #fff;
    font-size: 20px;
    display: inline-block;
    float: right;
    box-shadow: 4px 4px 4px #00000029;
    padding: 7px 16px;
    border-radius: 26px;
    margin-top: 20px;
    font-family: ComfortaBold;
    cursor: pointer;
    margin-bottom: 0;
    margin-top: 0;

    height: 53px;
    min-width: 117px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.customDropContainer {
    height: 100%;
    display: flex;
    align-items: center;
    align-self: center;
    align-content: center;
    width: 80%;
    margin: auto;
}

.minusicon {
    float: right;
    padding-top: 10px;
    font-size: 25px;
    cursor: pointer;
    color: #83838b;
}

.time {
    font-family: RobotoMedium;
    font-size: 30px;
    background-color: #e9f3fb;
    color: #707070;
    display: inline-block;
    padding: 5px;
    border-radius: 15px;
}

.rightTime {
    position: absolute;
    top: 8vw;
    right: 3vw;
    font-family: RobotoMedium;
    font-size: 30px;
    background-color: #e9f3fb;
    color: #707070;
    padding: 0.5vw 2.5vw;
    border-radius: 25px;
}

@media (max-width: 1200px) {
    .logoImg {
        position: fixed;
        width: 43vw;
        right: -10vw;
        top: -19vw;
    }

    .rightTime {
        top: 10vw;
        right: 6vmax;
        font-size: 3vw;
        border-radius: 4vmax;
        padding: 4px 20px;
    }
}

@media (max-width: 600px) {
    .logoImg {
        width: 50vw;
        right: -9vw;
        top: -18vw;
    }

    .rightTime {
        top: 16vw;
        left: 1vw;
        right: 9vw;
        font-size: 20px;
        padding: 0vh 3vw 0vh 2vw;
        border-radius: 4vw;
    }
}

.rightTabSection {
    position: absolute;
    left: -20px;
    top: 29%;
    z-index: 0;
    overflow: hidden;
    height: 29rem;
    padding-right: 20px;
}

.rightTabContainer {
    background-color: #f5c6a2;
    position: relative;
    text-align: left;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    border-radius: 26px;
    padding: 11px 20px 8px 45px;
}

.rightTab {
    margin-bottom: 0;
    color: #707070;
    font-family: ComfortaBold;
    font-size: 27px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;

    max-width: 155px;
}

[contenteditable] {
    outline: 0px solid transparent;
}

.createButtonSection {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    align-items: center;
}

.createButtonSectionText {
    color: #707070;
    font-size: 15px;
    font-family: RobotoItalic;
}

.createButton {
    border-radius: 26px;
    padding: 13px 18px;
    font-size: 22px;
    color: #fff;
    background-color: #88addb;
    font-family: ComfortaBold;
    box-shadow: 4px 4px 4px #00000029;
    cursor: pointer;
    margin-bottom: 0;
    margin-top: 3%;
    float: right;
    height: 53px;
    min-width: 117px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.paneloption {
    position: absolute;
    left: 12px;
    top: 20px;
    background-color: #c8e8e5;
    border-radius: 10px;
    padding: 1px;
    font-size: 13px;
}

.paneloption p {
    margin: 0;
    color: #707070;
    cursor: pointer;
}

.importModal {
    background-color: #f3f3f3;
    box-shadow: 4px 4px 4px #00000029;
    position: fixed;
    top: 25%;
    left: 25%;
    z-index: 8798797979879;
    padding: 30px 35px;
    border-radius: 21px;
    height: 400px;
    width: 35vw;
}

.logoImg {
    /* position: absolute;
    left: -210px;
    top: -250px; */
    position: fixed;
    width: 39vw;
    right: -9vw;
    top: -18vw;
}

.logoText,
.logoSubText {
    text-align: center;
    font-family: ComfortaBold;
    font-size: 2.8vw;
    line-height: 67px;
    letter-spacing: 0.3px;
    color: #ffffff;
    position: fixed;
    top: 1.2vw;
    right: 1.8vw;
    margin-bottom: 0;
}

.logoSubText {
    font-size: 2.2vw;
    top: 4.4vw;
    right: 5.9vw;
}

.crossIcon {
    position: absolute;
    right: 20px;
    top: 13px;
    color: #e36b2d;
    cursor: pointer;
}

.classModalText {
    color: #707070;
    font-family: RobotoMediumItalic;
    font-size: 20px;
}

.modalClassWrapper {
    border: 1px solid #83838b;
    height: 80%;
    padding: 20px;
    border-radius: 18px;
    padding-right: 10px;
}

.modalClassScroll {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 10px;
}

.modalLessonWrapper {
    display: flex;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 10px;
}

.modalLesson {
    height: 80px;
    border: 1px solid #83838b;
    border-radius: 21px;
    min-width: 6.3vw;
    margin-right: 10px;
    cursor: pointer;
}

.modalLessonName {
    color: #707070;
    font-size: 17px;
    font-family: RobotoMediumItalic;
    text-align: center;
    padding: 20px;
}

.modalLessonWrapper::-webkit-scrollbar,
.modalClassScroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}

.modalLessonWrapper::-webkit-scrollbar-thumb,
.modalClassScroll::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.modalClassBlock {
    height: 80px;
    border: 1px solid #83838b;
    border-radius: 21px;
    cursor: pointer;
}

.modalCoverImage {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.modalClassName {
    color: #707070;
    font-size: 17px;
    font-family: RobotoMediumItalic;
    text-align: center;
}

.turnBasedText {
    width: 4vw;
}

.turnBasedButton {
    margin-right: 16vw;
}

.activityImg {
    height: 100%;
    width: 100%;
    border-radius: 26px;
}

.previewText {
    line-height: 24px;
    font-family: RobotoMediumItalic;
    font-size: 18px;
    letter-spacing: 0.09px;
    color: #e36b2d;
    text-align: center;
    position: relative;
    top: 10vh;
}

.previewModal {
    margin-top: 15vh;
    margin-bottom: 10vh;
}
