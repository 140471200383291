.mainDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.formWrapper {
    border: 1px solid #83838b;
    border-radius: 21px;
    box-shadow: -4px 4px 4px #00000029;
    max-width: 25rem;
    padding: 1.75rem 1.875rem;
    margin: 0 1.8rem;
    background-color: #fff;
    height: 65vh;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.formWrapper::-webkit-scrollbar {
    display: none;
}

input[type="date"]:invalid::-webkit-datetime-edit {
    color: #999;
}

input[type="date"] {
    display: block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-width: 95%;
}

.heading {
    color: #88addb;
    font-size: 35px;
}

.typeWrapper {
    display: flex;
    justify-content: space-around;
}

.checkBtn {
    width: 25px;
    height: 25px;
    border: 1px solid #707070;
    border-radius: 5px;
    margin-right: 1rem;
}

.type {
    color: #707070;
    font-size: 18px;
}

.nameInputWrapper {
    margin-bottom: 10px;
}

.label {
    color: #88addb;
    font-size: 21px;
    margin: 0px;
}

.topInput {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    border-radius: 21px 21px 0px 0px;
    width: 100%;
    padding: 10px;
    color: #707070;
}

.middleInput {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    // border-radius: 21px 21px 0px 0px;
    width: 100%;
    padding: 10px;
    color: #707070;
}

.bottomInput {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    border-radius: 0px 0px 21px 21px;
    width: 100%;
    padding: 10px;
    color: #707070;
}

.anotherStudent {
    color: #88addb;
    font-size: 16px;
    cursor: pointer;
}

.continueBtn {
    padding: 10px;
    background-color: #88addb;
    color: #fff;
    text-align: center;
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #88addb;
    border-radius: 18px;
    margin-top: 20px;
    cursor: pointer;
    font-family: ComfortaBold !important;
}

.fileupload {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    border-radius: 21px;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.imageName__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.imageName_sub_container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.imageName_buttons_container {
    color: #88addb;
    cursor: pointer;

    span {
        margin: 0 11px;
    }
}

.uploadModal {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8798797979879;
    width: 100vw;
    height: 100vh;
    display: flex;

    .uploadModalContainer {
        background-color: #f3f3f3;
        border-radius: 21px;
        box-shadow: 4px 4px 4px #00000029;
        padding: 30px 35px;
        margin: auto;
        max-width: 80vw;
        max-height: 80vh;
    }
}

.crossIcon {
    position: relative;
    left: 100%;
    color: #e36b2d;
    cursor: pointer;
    top: -2%;
}

.previewImage__container {
    max-height: 90vh;
    display: flex;

    img {
        max-height: 70vh;
        margin: auto;
    }
}

.toolText {
    margin: 0px;
    color: #707070;
}

.uploadIcon {
    background-color: #88addb;
    color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
}

.fileInput {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    border-radius: 21px;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    display: none;
}

.numberInput {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    border-radius: 21px;
    width: 100%;
    padding: 10px;
}

.para {
    font-size: 14px;
    color: #707070;
    text-align: justify;
}

.radiobtn {
    display: flex;
    //   align-items: center;
}

.datepickerPopper {
    z-index: 9999 !important;
}

@media only screen and (max-width: 460px) {
    .formWrapper {
        font-size: 0.9em;
        min-width: 20rem;
        padding: 1.75rem 1.5rem;
    }

    .heading {
        font-size: 1.7em;
    }

    .toolText {
        font-size: 1em;
        margin-top: 3px;
    }

    .continueBtn,
    .para,
    .loginText {
        font-size: 0.9em;
    }
}
