.heading {
    color: #002b4a;
    font-size: 25px;
    font-family: Comforta;
    font-weight: bold;
    padding: 5px 0px 10px;
}

.ratingBox {
    margin-top: 1%;
    margin-left: 1%;
    margin-bottom: 2%;
    padding-top: 1%;
    border: 1px solid #83838b;
    border-radius: 21px;
    box-shadow: -4px 4px 4px #00000029;
    background-color: #fff;
    padding: 28px 30px;
    width: 56rem;
    position: relative;
}

.className {
    font-weight: bold;
    font-family: Comforta;
    font-size: 20px;
    margin-bottom: 3.75px;
}

.TeacherName {
    font-family: RobotoMedium;
    font-size: 18px;
}

.ratingTitle {
    font-family: Comforta;
    font-weight: bold;
    font-size: 20px;
    color: #002b4a;
    width: 25%;
    margin-bottom: 0px;
}

.ratingTitlePending {
    font-family: Comforta;
    font-weight: bold;
    font-size: 20px;
    color: #002b4a;
    width: 40%;
    margin-bottom: 0px;
}

.reviewDetail {
    font-family: 22px;
}

.ratings {
    margin-left: 25px;
    margin-bottom: 1%;
}

.review {
    margin-bottom: 8px;
    margin-top: 14px;
    font-weight: bold;
    color: #002b4a;
    font-family: Comforta;
    font-weight: bold;
}

.starBox {
    margin-top: 0px;
}

.noRating {
    font-size: 20px;
    margin-bottom: 2rem;
    font-family: RobotoMedium;
}

.seeList {
    color: #88addb;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
}

.studentScroll {
    overflow-x: auto;
    overflow-y: auto;
    height: 65vh;
    width: 100%;
}

.studentScroll::-webkit-scrollbar {
    height: 5px;
    width: 7px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}

.studentScroll::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.appearLine {
    font-family: RobotoMedium;
    font-size: 18px;
    margin-bottom: 8px;
    margin-top: 16px;
    margin-left: 50px;
}

.reviewInput {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    border-radius: 16px;
    padding: 10px;
    width: 96%;
    box-sizing: unset;
}

.reviewInput:focus {
    outline: none !important;
    border-color: #83838b;
}

.submitButton {
    margin-bottom: 2%;
    cursor: pointer;
    width: 110px;
    height: 58px;
}

.uploadModal {
    background-color: #ffffff;
    box-shadow: 4px 4px 4px #00000029;
    position: fixed;
    top: 15%;
    left: 25%;
    z-index: 8798797979879;
    padding: 30px 35px;
    border-radius: 21px;
    width: 40vw;
}

.crossIcon {
    position: absolute;
    right: 20px;
    top: 13px;
    color: #e36b2d;
    cursor: pointer;
}

.modalHeading {
    font-size: 18px;
    font-family: Comforta;
}

.uploadArea {
    border: 1px solid #707070;
    padding: 25px 10px;
    border-radius: 18px;
    width: 35vw;
    height: fit-content;
    margin-bottom: 20px;
}

.uploadArea:focus {
    outline: none !important;
    border-color: #83838b;
}

.uploadBtn {
    display: inline-flex;
    justify-content: space-between;
    border: 1px solid #83838b;
    box-shadow: 4px 4px 4px #00000029;
    padding: 7px 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 26px;
    cursor: pointer;
}

.toolText {
    margin: 0px;
    margin-right: 20px;
}

.uploadIcon {
    color: #fff;
    height: 25px;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    background-color: #88addb;
}

.previewDelete {
    cursor: pointer;
    color: #88addb;
    margin-left: 5%;
}

.uploadModalImage {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8798797979879;
    width: 100vw;
    height: 100vh;
    display: flex;
}
.uploadModalContainer {
    background-color: #f3f3f3;
    border-radius: 21px;
    box-shadow: 4px 4px 4px #00000029;
    padding: 30px 35px;
    margin: auto;
    max-width: 80vw;
    max-height: 80vh;
}
.crossIconImageModal {
    position: relative;
    left: 100%;
    color: #e36b2d;
    cursor: pointer;
    top: -2%;
}

.previewImage__container {
    max-height: 90vh;
    display: flex;
}
.previewImage__container img {
    max-height: 70vh;
    margin: auto;
}

.carousel {
    width: 12.8rem;
    height: 9.5rem;
    position: absolute;
    right: 4%;
}

.cardHeading {
    width: 60%;
}

.ratingsBox {
    width: 60%;
}
