#container {
    height: 100%;
}

#userHeader {
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
}

#heading {
    text-align: center;
}

#icon {
    float: right;
    margin: 40px 10px 0px 0px;
    cursor: pointer;
}
