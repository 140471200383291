.content {
    box-shadow: 4px 4px 4px #00000029;
    border: 2px solid #e9f3fb;
    border-radius: 21px;
    padding: 6vh 2vw;
    height: 30%;
}

.backdrop {
    opacity: 0 !important;
}

.body {
    border: 1px solid #707070;
    border-radius: 18px;
    text-align: center;
    font-family: ComfortaBold;
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 0.09px;
    color: #707070;
    padding: 5vh 2vw;
    margin: auto;
}
