.chatListContainer {
    color: #707070;

    .chatListTitle {
        font-size: 1.3em;
        margin-bottom: 1rem;
        font-family: ComfortaBold;
        box-shadow: #00000029 0 3px 6px;
        padding-left: 2rem;
        cursor: pointer;
        display: flex;
        gap: 0.7rem;

        .classListHeader {
            display: flex;
            align-items: center;
            gap: 0.8rem;

            .classListTitleName {
                display: inline-block;
                max-width: 10.5em;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
            }

            .classListTitleNameActive {
                text-decoration: underline;
            }
        }
    }

    .showMoreLabel {
        text-align: center;
        cursor: pointer;
        font-family: ComfortaBold;
        background-color: #f5f6f7;
        transition: background-color 0.1s ease-out 0s,
            box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;

        span {
            color: #42526e;
            font-size: 14px;
        }
    }

    .showMoreLabel:hover {
        background-color: #ebedf0;
    }

    .newChatLabel {
        width: 100%;
        text-align: right;
        border-bottom: 1px solid #de4e2b;
        font-size: 13px;
        line-height: 0.1em;
        margin: 1rem 0;

        span {
            background: #fff;
            color: #de4e2b;
            font-family: ComfortaBold;
            padding-left: 5px;
        }
    }

    .chatContainer {
        display: flex;
        margin: 1rem 0;
        padding-left: 2rem;
        padding-right: 1rem;

        .chatFromUser {
            flex: 1;
        }

        .chatMessageOuterContainer {
            max-width: 39.25rem;
            display: flex;
            flex-flow: column;
            background-color: #e9f3fb;
            border-radius: 21px;
            padding: 1rem;

            .chatMessageTimeContainer {
                font-size: 15px;
                font-style: italic;
                text-align: right;
            }

            .chatMessageContainer {
                display: flex;
                // align-items: center;
                margin-top: 0.5rem;

                .recipientProfilePhotoContainer {
                    height: 2.5rem;
                    width: 2.5rem;

                    .recipientProfilePhoto {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        width: 100%;
                        border-radius: 100%;
                    }

                    span {
                        background-color: #f8d7c6;
                        color: #ffffff;
                        user-select: none;
                    }

                    .studentProfilePhoto {
                        background-color: #88addb;
                    }
                }

                .chatMessage {
                    flex: 1;
                    margin-left: 0.8rem;
                    white-space: pre-line;

                    .ImageGridComponent {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        align-items: center;
                        justify-items: center;
                        gap: 0.9rem;

                        .gridContainer {
                            width: fit-content;
                            display: flex;
                            align-items: center;
                            gap: 0.5rem;

                            img,
                            video {
                                height: 100%;
                                max-height: 100px;
                                max-width: 110px;
                                border-radius: 10px;
                                cursor: pointer;
                            }

                            .downloadIconContainer {
                                font-size: 1.5em;
                                color: #bbbebe;
                                cursor: pointer;
                            }
                        }
                    }

                    .toggleText {
                        cursor: pointer;
                        font-family: ComfortaBold;
                        width: fit-content;
                    }

                    .docFileContainer,
                    .docFileContainer a {
                        color: #707070;
                        display: flex;
                        align-items: center;
                        justify-items: center;
                        gap: 1rem;
                        cursor: pointer;
                        width: fit-content;

                        .fileIconContainer,
                        .downloadIconContainer {
                            font-size: 1.5em;
                            color: #bbbebe;
                        }

                        .fileName {
                            max-width: 15rem;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            word-break: break-all;
                        }
                    }

                    .docFileContainer:hover {
                        .fileName {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .chatMessageOuterContainerFromUser {
            background-color: #ebebeb;
            align-items: flex-end;

            .chatMessageContainer {
                flex-flow: row-reverse;

                .chatMessage {
                    margin-left: 0;
                    margin-right: 0.8rem;
                }
            }
        }
    }
}

@media screen and (max-width: 865px) {
    .chatListContainer {
        .chatListTitle {
            flex-flow: column;
            padding-right: 2rem;
            width: 100vw;

            .classListHeader {
                font-size: 0.99em;

                .classListTitleName {
                    flex: 1;
                }
            }
        }
    }
}
