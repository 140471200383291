#container {
    height: 100%;
}

#sessionHeader {
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
}

#icon {
    float: right;
    margin: 40px 10px 0px 0px;
}
