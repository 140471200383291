.parentWrapper {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
}

.logoRight {
    position: absolute;
    right: -100px;
    top: -250px;
}

.outerBlock {
    padding: 20px;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
}

.wrapper {
    height: 93vh;
    position: relative;
    /* background-color: #ffffff; */
}

.wrapperScreenShare {
    height: 68vh; /* 90vh */
    border: 1px solid #707070;
    border-radius: 25px;
    box-shadow: -4px 4px 4px #00000029;
    position: relative;
    background-color: #ffffff;
}

.wrapperWB {
    height: 63vh; /* 90vh */
    border: 1px solid #707070;
    border-radius: 25px;
    box-shadow: -4px 4px 4px #00000029;
    position: relative;
    background-color: #ffffff;
}

.logo {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 1;
}

.clock {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #e9f3fb;
    box-shadow: 0px 4px 8px #00000029;
    opacity: 0.4;
    padding: 2px 20px;
    font-size: 27px;
    z-index: 1;
    border-radius: 23px;
}

.switch {
    margin-bottom: 10px;
    padding: 10px 10px 10px 20px;
    border-radius: 30px 0px 0px 30px;
    position: absolute;
    right: 0px;
}

.switch p {
    margin: 0px;
    color: #707070;
    font-size: 25px;
}

.buttonWrapper {
    position: absolute;
    width: 100%;
    bottom: 0px;
}

.btn {
    padding: 10px 17px 10px 58px;
    background-color: #ebebeb;
    border-radius: 30px 30px 0px 00px;
}

.btn p {
    margin: 0px;
    color: #88addb;
    font-size: 25px;
}

.icon {
    margin: 0px 6px;
    width: 55px;
}

.icon:hover {
    border: 1px solid #707070;
    border-radius: 50%;
}

.line {
    margin-top: 0px;
    margin-bottom: 0px;
    border-top: 4px solid #707070;
}

.btnText {
    display: flex;
    align-items: center;
}

.effectsWrapper {
    position: absolute;
    top: -170px;
    left: -80px;
    background-color: #fff;
    width: 200px;
    height: 160px;
    padding: 10px;
    box-shadow: 4px 4px 4px #00000029;
    border-radius: 21px;
}

.stickerContainer {
    border-radius: 20px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.stickerScroll {
    height: 100px;
    overflow: hidden;
    overflow-y: scroll;
}

.stickerRow {
    display: flex;
    justify-content: space-around;
    margin-bottom: 5px;
    margin-top: 5px;
}

.sticker {
    border-radius: 26px;
    margin: auto;
    color: #707070;
    cursor: pointer;
    padding: 5px 0px;
}

.stickerWraper p {
    font-size: 12px;
    /* padding: 10px 15px; */
    border-radius: 26px;
    margin: auto;
    color: #707070;
    cursor: pointer;
    padding: 5px 0px;
}

.stickerWraper {
    text-align: center;
    width: 45%;
    border: 1px solid #83838b;
    border-radius: 16px;
    display: flex;
    align-items: center;
    height: 50px;
}

.stickerWraper img {
    height: 100%;
}

.stickerScroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}
.stickerScroll::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.screenArrangementWrapper {
    position: absolute;
    top: -170px;
    left: -150px;
    background-color: #fff;
    width: 210px;
    height: 160px;
    padding: 10px;
    box-shadow: 4px 4px 4px #00000029;
    border-radius: 21px;
}

.screenArrangementScroll {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}

.screenArrangementScroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}

.screenArrangementScroll::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.arrangementTab {
    display: flex;
    justify-content: space-around;
}

.arrangementTab p {
    font-size: 15px;
    border: 1px solid #a7c0db;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
}

.imageSection img {
    margin-top: 3px;
    margin-bottom: 3px;
}

.modal {
    border-radius: 25px !important;
    box-shadow: 4px 4px 4px #00000029;
}

.modalSubBlock {
    border: 1px solid #707070;
    border-radius: 25px;
    box-shadow: 4px 4px 4px #00000029;
    position: relative;
    background-color: #ffffff;
    margin: 10px;
    width: 100%;
    padding: 10px 25px;
}

.teamModal {
    position: absolute;
    top: 30px;
    right: -300px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 3px 6px 6px #00000029;
    border: 1px solid #707070;
    border-radius: 21px;
}

.teamModal p {
    margin-bottom: 0px;
    margin-top: 10px;
}

.textConfig {
    color: #707070;
    font-size: 20px;
    font-family: RobotoMediumItalic;
}

.btnCircle {
    width: 30px;
    height: 30px;
    border: 1px solid #707070;
    border-radius: 50%;
    line-height: 30px;
    text-align: center;
    margin: auto;
    cursor: pointer;
    color: #707070;
}

.modalHeading {
    text-align: left;
    position: relative;
    left: 20px;
    /* font: normal normal bold 20px/23px Comfortaa !important; */
    letter-spacing: 0.1px;
    color: #e36b2d;
    opacity: 1;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 23px;
}

.divRow {
    margin-top: 15px;
    margin-bottom: 15px;
}

.modalText {
    font-family: RobotoMediumItalic;
    color: #707070;
    font-weight: 600;
    font-size: 15px;
    font-style: italic;
}

.modalTextBigger {
    display: flex;
    justify-content: space-around;
    font-family: RobotoMediumItalic;
    color: #707070;
    font-size: 20px;
}
/* roboto 15 medium italic */

.buttonRightText {
    padding-left: 0;
    text-align: left;
    padding-right: 0;
}

.buttonLeftText {
    padding-right: 0;
    text-align: right;
    padding-left: 0;
}

.button {
    height: 40px;
    border-radius: 50px;
    padding: 4px;
    cursor: pointer;
    width: 100%;
    box-shadow: 4px 4px 4px #00000029;
    border: 1px solid #83838b;
}

.circleRight {
    height: 30px;
    width: 30px;
    background-color: #88addb;
    border-radius: 50%;
    float: right;
}

.circleLeft {
    height: 30px;
    width: 30px;
    background-color: #88addb;
    border-radius: 50%;
    float: left;
}

.createButtonSectionText {
    color: #707070;
    font-size: 12px;
    font-family: RobotoItalic;
    text-align: center;
    display: block;
    margin-top: 10px;
}

.modalBackdrop {
    opacity: 0 !important;
}

.startButtonSection {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.startButton {
    border-radius: 26px;
    padding: 13px 18px;
    font-size: 22px;
    color: #fff;
    background-color: #88addb;
    font-family: ComfortaBold;
    box-shadow: 4px 4px 4px #00000029;
    cursor: pointer;
    margin-bottom: 0;
    height: 53px;
    min-width: 117px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.teacherOnlyAccButton {
    margin-left: 14px;
}

.allAccButton {
    margin-left: 7px;
}

.imgSize {
    height: 100%;
    width: 100%;
}

.cursor {
    cursor: url("../../../assets/images/pngkit_cursor-png_180101(32px).png"),
        auto;
}

.selected {
    border: 2px solid #88addb;
}

.switchContainer {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 5;
}

.switchWrapper {
    margin: 35px 0;
}

.uploadModalImage {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8798797979879;
    width: 100vw;
    height: 100vh;
    display: flex;
}
.uploadModalContainer {
    background-color: #f3f3f3;
    border-radius: 21px;
    box-shadow: 4px 4px 4px #00000029;
    padding: 30px 35px;
    margin: auto;
    max-width: 80vw;
    max-height: 80vh;
}

.uploadModal {
    background-color: #ffffff;
    box-shadow: 4px 4px 4px #00000029;
    position: fixed;
    top: 15%;
    left: 34%;
    z-index: 8798797979879;
    padding: 38px 35px 20px;
    border-radius: 21px;
    width: 32vw;
}

.uploadArea {
    border: 1px solid #707070;
    padding: 25px 10px;
    border-radius: 18px;
    width: 27vw;
    height: fit-content;
    margin-bottom: 20px;
}

.uploadArea:focus {
    outline: none !important;
    border-color: #83838b;
}

.leaveModalHeading {
    font-size: 18px;
    font-family: Comforta;
    margin-bottom: 0px;
}

.actionButtonsContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 0;
}

.actionButton {
    font-family: Comforta;
    background-color: #e36b2d;
    color: #fff;
    padding: 7px 16px;
    min-width: 117px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    border-radius: 26px;
    cursor: pointer;
}

.primaryActionButton {
    background-color: #87aedb;
}

.crossIcon {
    position: absolute;
    right: 20px;
    top: 13px;
    color: #e36b2d;
    cursor: pointer;
}
