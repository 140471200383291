.progressContainer {
    width: 30%;
}

.volContainer {
    width: 20%;
}

.volSlider {
    width: 100%;
}

.progressSlider {
    width: 100%;
}

.controls {
    position: absolute;
    background: rgba(69, 68, 68, 0.6);
    justify-content: center;
    border-radius: 24px;
}

.controlsRow {
    height: 1.5rem;
}

.audioContainer {
    height: 100%;
    width: 100%;
    justify-content: center;
    display: flex;
}

.slider {
    -webkit-appearance: none;
    height: 0.1rem;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    position: relative;
    bottom: 0.3rem;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 0.7rem;
    height: 0.7rem;
    background: white;
    cursor: pointer;
}

.button {
    color: white;
    margin-left: 5px;
    margin-right: 5px;
}

.assets {
    width: 100%;
    height: 100%;
    border-radius: 24px;
}

.rowCentered {
    justify-content: center;
}
