.blockTeacher {
  border-radius: 25px;
  height: 100%;
  position: relative;
}

.block {
  box-shadow: 3px 6px 6px #00000029;
  border: 1px solid #707070;
  border-radius: 24px;
  height: 22vh;
  margin-bottom: 20px;
}

.blockMedia {
  position: absolute;
  top: 10px;
  right: 10px;
  max-width: 30vw;
  width: 16vw;
  height: 26vh;
}

.rowDiv {
  position: fixed;
  bottom: 2vh;
  width: 100%;
  width: calc(100% - 30px);
  left: 0;
  margin-left: 15px;
  margin-right: 15px;
}

.assets {
  width: 100%;
  height: 100%;
  border-radius: 24px;
}

.image {
  object-fit: contain;
}

.selected {
  border: 5px solid #fcb648;
}

