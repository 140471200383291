.mainDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.formWrapper {
    border: 1px solid #83838b;
    border-radius: 21px;
    box-shadow: -4px 4px 4px #00000029;
    max-width: 25rem;
    padding: 1.75rem 1.875rem;
    margin: 0 1.8rem;
    background-color: #fff;
}

.heading {
    color: #88addb;
    font-size: 35px;
    margin: 0px;
}

.inputWrapper {
    margin-top: 10px;
    margin-bottom: 10px;
}

.emailInput {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    border-radius: 21px;
    width: 100%;
    padding: 10px;
}

.passwordInput {
    margin-top: 15px;
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    border-radius: 21px;
    width: 100%;
    padding: 10px;
}

.continueBtn {
    padding: 10px;
    background-color: #88addb;
    color: #fff;
    text-align: center;
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #88addb;
    border-radius: 18px;
    margin-bottom: 7px;
    cursor: pointer;
    font-family: ComfortaBold !important;
}

.para {
    color: #88addb;
    text-align: center;
    cursor: pointer;
    font-family: ComfortaBold !important;
}

.signupText {
    color: #707070;
    margin-top: 2rem;
    font-family: ComfortaBold !important;
}

.signup {
    color: #88addb;
    cursor: pointer;
}

@media only screen and (max-width: 460px) {
    .formWrapper {
        font-size: 0.9em;
        min-width: 20rem;
        padding: 1.75rem 1.5rem;
    }

    .heading {
        font-size: 1.7em;
    }

    .continueBtn,
    .para,
    .signupText {
        font-size: 0.9em;
    }
}
