.btn {
    background-color: #ffffff;
    width: 92px;
    height: 60px;
    border: 1px solid #e5e2e2;
}

#bar {
    width: 90%;
    height: 5px;
    background-color: #e08440;
    border-radius: 3px;
    margin-top: 20px;
    margin-left: 22px;
    margin-bottom: 10px;
}

.modalBody {
    text-align: center;
}

.body_div {
    display: flex;
    align-self: center;
    margin-bottom: 36px;
}
