.logoDiv {
    position: fixed;
    left: -11vw;
    top: -17vw;
    z-index: 1;
    cursor: pointer;
}

.logoContainer {
    position: absolute;
    top: 0;
    left: 0;
}

.studentLogoBg {
    width: 22.5rem;
    position: fixed;
}

.logoText {
    text-align: center;
    font-family: ComfortaBold;
    font-size: 37px;
    line-height: 67px;
    letter-spacing: 0.3px;
    color: #ffffff;
    position: fixed;
    top: 3.5rem;
    left: 3rem;
    margin-bottom: 0;
}

.logoImg {
    width: 38vw;
}

.parentWrapper {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
}

.outerBlock {
    padding: 20px;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
}

.wrapper {
    border: 1px solid #707070;
    border-radius: 25px;
    box-shadow: -4px 4px 4px #00000029;
    height: 93vh;
    position: relative;
    background-color: #ffffff;
    padding: 10vh 2vw 10vh 5vw;
}

.content {
    margin: 27vh auto;
    width: 40vw;
}

.starsImg {
    width: 25vw;
    position: fixed;
    right: 1vw;
    top: 1vh;
    z-index: 1;
}

h1 {
    color: #88addb;
    font-size: 35px;
    line-height: 39px;
    letter-spacing: 0.17px;
    font-family: ComfortaBold;
}

.p1 {
    color: #707070;
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0.11px;
    font-family: RobotoMediumItalic;
    margin-top: 4vh;
}

.p2 {
    color: #707070;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.17px;
    font-family: ComfortaBold;
    margin-top: 4vh;
}

a {
    color: #88addb;
}
