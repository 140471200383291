* {
    box-sizing: border-box;
}

.colors {
    position: fixed;
}

.color {
    display: inline-block;
    height: 48px;
    width: 48px;
}

.color.black {
    background-color: black;
}
.color.red {
    background-color: red;
}
.color.green {
    background-color: green;
}
.color.blue {
    background-color: blue;
}
.color.yellow {
    background-color: yellow;
}

.Whiteboard_main {
    background: #f7f2f2 0% 0% no-repeat padding-box;
    /* height: 541px; */
    height: 50vh;
    border-radius: 24px;
    opacity: 1;
    overflow: hidden;
    width: 100%;
    position: relative;
}

.whiteboard_control {
    display: flex;
    justify-content: center;
    /* border: 1px solid black; */
    background-color: #ebebeb;
    align-items: center;
    width: fit-content;
    margin: auto;
    padding: 10px;
    box-shadow: 4px 4px 4px #00000029;
    border-radius: 0px 0px 21px 21px;
    opacity: 0.7;
}

.whiteboard_shape_wrapper {
    display: flex;

    align-items: center;
}

.whiteboard_shape_wrapper p {
    margin: 0px;
    margin-right: 10px;
}

#eraser {
    /* position: absolute;
  top: 15px;
  left: 15px; */
    cursor: pointer;
    margin-right: 10px;
}

.whiteboard_color_wrapper {
    display: flex;
    border: 1px solid #707070;
    border-radius: 20px;
    padding: 5px;
    margin-right: 10px;
    /* flex-direction: column;
  position: absolute;
  top: 70px;
  left: 15px; */
}

.whiteboard_color {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 5px;
}

.whiteboard_width_wrapper {
    display: flex;
    border: 1px solid #707070;
    border-radius: 20px;
    padding: 5px;
    margin-right: 10px;
}

.whiteboard_width {
    margin-right: 10px;
}

.whiteboard_width:hover {
    background-color: black;
}

.clear_wrapper {
    position: relative;
}

.clear_option {
    position: absolute;
    background-color: rgb(200, 232, 229);
    width: 80px;
    padding: 5px;
    border-radius: 10px;
}

.clear_option p {
    margin-bottom: 0px;
    font-size: 14px;
    cursor: pointer;
}

.whiteboard_text {
    border: 1px dashed #707070;
    padding: 3px;
}

.whiteboard_text p {
    margin: 0px;
}

.info {
    width: 100px;
    border: 1px solid black;
    position: absolute;
    height: 30px;
    transform: scale(1.5);
    /* top: 100px;
  left: 100px; */
    z-index: 100000;
}
