.waiting_screen {
    color: #002b4a;
}

.logoContainer {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.studentLogoBg {
    width: 22.5rem;
    position: fixed;
}

.logoText {
    text-align: center;
    font-family: ComfortaBold;
    font-size: 37px;
    line-height: 67px;
    letter-spacing: 0.3px;
    color: #ffffff;
    position: fixed;
    top: 3.5rem;
    left: 3rem;
    margin-bottom: 0;
}

.logo {
    position: absolute;
    z-index: -10;
    left: -250px;
    top: -350px;
    max-width: 800px;
}

.waiting_content__container {
    height: calc(100vh - 205px);
    max-width: 482px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 35px;
    color: #707070;
    text-align: center;
    margin: auto;
    padding: 0 2rem;
}

.waiting_content__container video {
    height: 8rem;
}

.error_content__container {
    height: calc(100vh - 205px);
    max-width: 572px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    font-size: 22px;
    padding: 0 2rem;
}

.error_content__container span {
    cursor: pointer;
    color: #88addb;
}

.error_heading {
    color: #88addb;
    font-size: 35px;
}

.success_content__container {
    height: calc(100vh - 205px);
    max-width: 594px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    font-size: 22px;
    padding: 0 2rem;
}

.success_content__container span {
    cursor: pointer;
    color: #88addb;
}

.success_heading {
    color: #88addb;
    font-size: 35px;
}

.plus_icons {
    position: absolute;
    top: 30px;
    right: 83px;
    max-width: 416px;
    height: 357px;
}

a:hover {
    text-decoration: none;
    color: #88addb;
}

.footer__container {
    position: fixed;
    bottom: 0;
    max-width: 100vw;
    text-align: center;
    font-size: 20px;
    color: #707070;
    font-style: italic;
}

@media screen and (max-width: 646px) {
    .error_content__container p,
    .success_content__container p {
        font-size: 0.8em;
    }

    .waiting_content__container,
    .success_heading,
    .error_heading {
        font-size: 1.5em !important;
    }
}
