.logoImg {
    position: fixed;
    width: 38vw;
    left: -11vw;
    top: -17vw;
}

.logoContainer {
    position: absolute;
    top: 0;
    left: 0.96rem;
    z-index: 70;
}

.topIcon {
    padding-left: 2px;
    display: flex;
    justify-content: flex-end;
    margin-left: 0%;
}

.hoverText {
    margin-bottom: 5px;
    visibility: hidden;
    /* opacity: 0; */
    width: 117px;
    height: 18px;
}

.iconColumn {
    display: flex;
    align-items: center;
    height: 20%;
    width: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
}

@media screen and (max-width: 768px) {
    .iconColumn {
        height: 20%;
        /* width: 20%; */
    }
}

.iconColumnStudent {
    display: flex;
    align-items: center;
    height: 20%;
    width: 9%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -13px;
}

@media screen and (max-width: 768px) {
    .iconColumnStudent {
        height: 20%;
        /* width: 20%; */
    }
}

.studentLogoBg {
    width: 25.15vw;
    max-width: 22.5rem;
    position: fixed;
}

.logoText,
.logoSubText {
    text-align: center;
    font-family: ComfortaBold;
    font-size: 2.58vw;
    line-height: 67px;
    letter-spacing: 0.3px;
    color: #ffffff;
    position: fixed;
    top: 2.3vw;
    left: 3.2vw;
    margin-bottom: 0;
}

.logoSubText {
    font-size: 2.24vw;
    top: 5.6vw;
    left: 5.7vw;
}

@media screen and (min-width: 1430px) {
    .logoText,
    .logoSubText {
        font-size: 37px;
        top: 2.3rem;
        left: 3rem;
    }

    .logoSubText {
        font-size: 32px;
        top: 5.2rem;
        left: 5.1rem;
    }
}

.studentModalBackdrop {
    opacity: 0;
}

.logoTextTeacher {
    left: 4.8rem;
    top: 5rem;
    font-size: 32px;
}

.topRightContainer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.topRight {
    margin-top: 2rem;
    float: right;
    color: #002b4a;
    font-size: 30px;
    height: 6vh;
    display: flex;
    align-items: flex-end;
    margin-right: 4%;
}

.mainSectionTeacher {
    background-color: #fdccb9;
    height: 100vh;
}

.mainSectionStudent {
    background-color: #e3f0fa;
    height: 100vh;
}

.middleSectionContainer {
    margin-left: -1rem;
    margin-right: 1rem;
}

.middleSection {
    width: 48vw;
    padding: 20px;
    background-color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    display: flex;
}

.middleImg {
    width: 100%;
    height: 19.5rem;
    margin: auto;
}

.studentTasks,
.teacherTasks {
    width: 48vw;
    height: 15rem;
}

.studentTasks img,
.teacherTasks img {
    width: 100%;
}

.tasksContainer {
    height: calc(100% - 61px);
    background-color: #fff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 0 2rem;
    color: #002b4a;
}

.tasksHeader {
    font-family: ComfortaBold !important;
    font-size: 27px;
}

.tasksList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: calc(100% - 61px);
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.tasksList::-webkit-scrollbar {
    display: none;
}

.taskContainer {
    display: flex;
    align-items: center;
    color: inherit;
}

.taskContainer:hover {
    color: inherit;
}

.taskSymbol {
    color: #dc542e;
    font-size: 20px;
    margin: 0.3rem 1rem;
}

.taskTitle {
    display: inline-block;
    width: 16.9rem;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

@media screen and (max-width: 1440px) {
    .tasksList {
        justify-content: flex-start;
    }

    .taskTitle {
        width: calc(48vw - 7rem);
    }
}

.rightSection {
    margin-top: 40px;
    position: absolute;
    right: 1.3rem;
}

.rightSection h1 {
    background-color: #dc531e;
    display: inline-block;
    padding: 10px;
    color: #fff;
    cursor: pointer;
}

.createBtn {
    background-color: #002b4a;
    display: inline-block;
    padding: 10px;
    color: #fff;
    cursor: pointer;
    margin-left: 40px;
}

.dashboardIcon {
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    cursor: pointer;
    height: 35px;
    width: 31px;
}

.unreadChatCount {
    position: absolute;
    font-size: 0.5em;
    color: #ffffff;
    background: #f3a32e;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    text-align: center;
    padding: 2px;
    left: 1.5rem;
    top: -0.5rem;
    cursor: pointer;
}

.createClassButton {
    /* box-shadow: 4px 4px 4px #00000029;
    border-radius: 26px;
    background: #88addb;
    text-align: left;
    font-size: 22px;
    line-height: 24px;
    font-family: ComfortaBold;
    letter-spacing: 0.11px;
    color: #ffffff;
    padding: 13px 18px;
    justify-content: center;
    display: flex; */
    float: right;
    cursor: pointer;
}

.dashboardHeading {
    font-size: 35px;
    line-height: 39px;
    font-family: ComfortaBold;
    letter-spacing: 0.17px;
    color: #002b4a;
    margin-bottom: 7vh;
}

.dashboardColHeading {
    font-family: RobotoMedium;
    color: #dc531e;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-bottom: 2vh;
}

.classRow {
    border-radius: 21px;
    background: #e9f3fb;
    color: #707070;
    font-family: RobotoMedium;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-bottom: 1vh;
    height: 7vh;
    cursor: pointer;
    margin-right: 10px !important;
    margin-left: 12px !important;
}

@media screen and (max-width: 1200px) {
    .classRow {
        height: 14vh;
    }
}

.dashboardModal {
    box-shadow: 4px 4px 4px #00000029;
    border: 2px solid #e9f3fb;
    border-radius: 21px;
    padding: 5vh 2vw;
}

.dashboardModalReview {
    box-shadow: 4px 4px 4px #00000029;
    border: 2px solid #e9f3fb;
    border-radius: 21px;
    padding: 2vh 2vh 0vh;
}

.dashboardModalBackdrop {
    opacity: 0;
}

.pencilIcon {
    float: right;
    cursor: pointer;
    height: 4vh;
    padding: 1vh;
}

.plusIcon {
    margin-left: 1vw;
    cursor: pointer;
    width: 7vh;
    height: 7vh;
}

.minusIcon {
    cursor: pointer;
    width: 7vh;
    height: 7vh;
}

.minusIconStudentModal {
    margin-left: 2vw;
}

.classDetail {
    margin: auto 0;
}

.classDetail .chatIcon {
    margin-left: 1rem;
}

.studentList {
    color: #707070;
    /* font-family: RobotoMediumItalic; */
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1px;
    /* float: right; */
    margin-top: 3rem;
    width: 100%;
    /* float: right; */
    /* display: flex; */
    /* justify-content: right; */
}

.parentNames {
    font-family: RobotoMedium;
}

.invitationBox {
    box-shadow: 4px 4px 4px #00000029;
    border-radius: 21px;
    background: #c8e8e5;
    width: 70%;
    padding: 3vh 1.5vw;
    max-height: 25vh;
}

.invitationBoxText {
    color: #707070;
    font-family: RobotoMedium;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1px;
}

.inviteLink {
    border-radius: 21px;
    background: #e9f3fb;
    width: 60%;
    display: flex;
    justify-content: space-between;
    min-height: 7vh;
    flex-direction: row;
    flex-wrap: wrap;
}

.copyButton {
    float: right;
    height: 7vh;
    cursor: pointer;
}

.copyIcon {
    height: 100%;
}

.link {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 1vw;
}

.removeStudentIcon {
    border-radius: 50%;
    border: solid;
    float: right;
    height: 5vh;
    width: 5vh;
    cursor: pointer;
    display: flex;
    margin-right: 1vw;
    margin-top: auto;
    margin-bottom: auto;
}

.minusFaIcon {
    margin: auto;
}

.dropdownItem {
    cursor: pointer;
    width: 100%;
    margin-top: 1rem;
    margin-left: 1rem;
}

.backButton {
    width: 7vw;
    background: #bbbebe;
}

.emailInput {
    background: inherit;
    border: none;
    width: 70%;
    border-radius: inherit;
    height: 100%;
    margin-left: 1vw;
}

button {
    background: inherit;
    border: none;
}

.addStudentForm {
    width: 100%;
}

.angleDownFaIcon {
    color: #75a3d7;
    font-size: 30px;
    cursor: pointer;
    margin-left: 1vw;
}

.studentsDropdown {
    display: inline-block;
    z-index: 2;
    position: relative;
    border-radius: 21px;
    padding-left: 1vw;
}

.welcomeText {
    float: left;
    font-family: ComfortaBold !important;
}

.studentName {
    cursor: pointer;
    font-family: ComfortaBold !important;
}

.teacherName {
    margin-left: 1vw;
    margin-right: 1.2vw;
    font-family: ComfortaBold !important;
}

.showStudentsDropdown {
    color: #75a3d7;
    background-color: #ffffff;
}

.playButton {
    cursor: pointer;
}

.playButtonFilter {
    filter: grayscale(100%);
}

.teacherBag,
.bag {
    width: 236px;
    height: 217px;
    margin-top: 4.8vw;
    margin-left: 3vw;
    position: absolute;
    z-index: 99;
}

.bag {
    cursor: pointer;
}

.teacherMoments,
.studentMoments {
    position: absolute;
    margin-left: 3vw;
    margin-top: 16.6rem;
    height: 22rem;
    width: 238px;
}

@media screen and (min-width: 1326px) {
    .teacherBag,
    .bag {
        margin-top: 5.7vw;
        margin-left: 4vw;
    }

    .teacherMoments,
    .studentMoments {
        margin-left: 4vw;
        margin-top: 17.5rem;
    }
}

@media screen and (min-width: 1430px) {
    .teacherBag,
    .bag {
        margin-top: 5rem;
        margin-left: 4rem;
    }

    .teacherMoments,
    .studentMoments {
        margin-top: 17.6rem;
        margin-left: 4rem;
    }
}

.topRow {
    height: 12vh;
    width: 100%;
}

.trophyImages {
    height: 100px;
    margin-right: 25px;
}

.deskContainer {
    position: relative;
    margin-top: 20px;
}

.deskTopShelf {
    top: 34px;
}

.deskBottomShelf {
    bottom: 87px;
}

.deskBottomShelfTeacher {
    bottom: 76px;
}

.deskShelf {
    display: flex;
    flex-flow: row;
    position: absolute;
    left: 10px;
}

.leftShelfItem {
    margin-right: 10px;
}

.cookieJar {
    height: 85px;
}

.islandFlag {
    margin-left: 36px;
    bottom: -25px;
    position: relative;
    height: 55px;
}

.topShelfIconContainer {
    width: 50px;
    height: 50px;
    margin: 0 3px;
}

.topShelfIcon {
    position: absolute;
    margin: 13px;
    z-index: 2;
}

.horizontalBooksContainer {
    margin-top: 15px;
}

.topShelfIcons {
    display: flex;
}

.classFileIcon {
    margin-right: 15px;
    width: 130px;
}

.videoIcon {
    margin-top: 16px;
    margin-left: 12px;
}

.modalHeading {
    font-size: 18px;
    font-family: Comforta;
}

.uploadArea {
    border: 1px solid #707070;
    padding: 25px 10px 4px;
    border-radius: 18px;
    /* width: 35vw; */
    height: fit-content;
    margin-bottom: 20px;
    text-align: center;
}

.uploadArea:focus {
    outline: none !important;
    border-color: #83838b;
}
.scrollContainer {
    height: 18vw;
    overflow: hidden;
    overflow-y: auto;
}

.scrollContainer::-webkit-scrollbar {
    height: 5px;
    width: 7px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}

.scrollContainer::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

/* 
.scrollDiv {
    overflow-x: scroll;
}

.tableData {
    border: 1px solid #83838b
}

.table2 {
    border-bottom: 1px solid #83738b
}

*/
.table td,
.table th {
    padding: 0.75rem;
    vertical-align: top;
    border-right: 1px solid rgb(204, 204, 204);
}

table .mytable td {
    border-right: 1px solid #86868b;
}

.mytable td:first-child,
.mytable th:first-child {
    border-radius: 21px 0 0 21px;
}

.mytable td:last-child,
.mytable th:last-child {
    border-radius: 21px 0 0 21px;
    border-right: none;
}

.mytable td:last-child,
.mytable tr:last-child {
    border-bottom: none !important;
}

/* .tableRow  */
/* 
.table2 td, .table2 th {
    padding: .75rem;
    vertical-align: top;
} */

table .mytable td:last-child {
    border-bottom: none;
}

.removeTag {
    color: #dc531e;
    font-size: 15px;
    float: right;
    cursor: pointer;
}

.removeTag:hover {
    text-decoration: underline;
}

.dropdownIcon {
    float: right;
    background-color: white;
    width: 30px !important;
    height: 30px !important;
    border-radius: 100px;
}

.uploadModal {
    background-color: #ffffff;
    box-shadow: 4px 4px 4px #00000029;
    position: fixed;
    top: 15%;
    left: 25%;
    z-index: 8798797979879;
    padding: 30px 35px 0px;
    border-radius: 21px;
    width: 40vw;
}

.crossIcon {
    position: absolute;
    right: 20px;
    top: 13px;
    color: #e36b2d;
    cursor: pointer;
}

.uploadAreaBox {
    padding: 25px 10px;
    border-radius: 18px;
    width: 35vw;
    height: fit-content;
    margin-bottom: 20px;
}

.uploadAreaBox:focus {
    outline: none !important;
    border-color: #83838b;
}
.modalHeading {
    font-size: 18px;
    font-family: Comforta;
}

.studentColumn {
    margin-right: 10px !important;
    margin-left: 12px !important;
}
