.inputContainer {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-top: 2%;
    padding-left: 3%;
    padding-right: 3%;
}

.plusIcon {
    height: 40px;
    width: 88px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.addRemoveIcon {
    height: 3rem;
    cursor: pointer;
}