@font-face {
    font-family: "CatalinaClementeRegular";
    src: local("CatalinaClementeRegular"),
        url("./assets/fonts/Kimmy Design - CatalinaClementeRegular.otf");
}
@font-face {
    font-family: "CatalinaClementeBoldItalic";
    src: local("CatalinaClementeBoldItalic"),
        url("./assets/fonts/Kimmy Design - CatalinaClementeBold-Italic.otf");
}
@font-face {
    font-family: "CatalinaClementeLight";
    src: local("CatalinaClementeLight"),
        url("./assets/fonts/Kimmy Design - CatalinaClementeLight.otf");
}

@font-face {
    font-family: "ComfortaBold";
    src: local("ComfortaBold"),
        url("./assets/fonts/comfortaa/Comfortaa-Bold.ttf");
}

@font-face {
    font-family: "ComfortaMedium";
    src: local("ComfortaMedium"),
        url("./assets/fonts/comfortaa/Comfortaa-Medium.ttf");
}

@font-face {
    font-family: "Comforta";
    src: local("Comforta"),
        url("./assets/fonts/comfortaa/Comfortaa-Regular.ttf");
}

@font-face {
    font-family: "RobotoMedium";
    src: local("RobotoMedium"), url("./assets/fonts/roboto/Roboto-Medium.ttf");
}

@font-face {
    font-family: "RobotoMediumItalic";
    src: local("RobotoMediumItalic"),
        url("./assets/fonts/roboto/Roboto-MediumItalic.ttf");
}

@font-face {
    font-family: "RobotoItalic";
    src: local("RobotoItalic"), url("./assets/fonts/roboto/Roboto-Italic.ttf");
}
@font-face {
    font-family: "RobotoRegular";
    src: local("RobotoRegular"), url("./assets/fonts/roboto/Roboto-Regular.ttf");
}

.carousel-status {
    display: none;
}

.OTPublisherContainer,
.OTSubscriberContainer {
    height: 100% !important;
    width: 100% !important;
}

/* p {
  margin: 0 !important;
} */

body {
    margin: 0;
    font-family: RobotoRegular !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #707070 !important;
}

button,
optgroup,
select,
textarea {
    font-family: RobotoRegular !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

html,
body,
#root {
    height: 100%;
}

@keyframes toggleOpacity {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOutOpacity {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.animateOpacity {
    animation: toggleOpacity 3s infinite;
}

.fadeOut {
    opacity: 0;
    animation: fadeOutOpacity 1s;
}

.flipper {
    -webkit-transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 0.6s;
    -moz-transform-style: preserve-3d;
    -o-transition: 0.6s;
    -o-transform-style: preserve-3d;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: relative;
}

.front,
.back {
    -webkit-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

p {
    margin-bottom: 0px;
}

.back {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.transform {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

video {
    object-fit: cover;
    height: 100%;
}
.css-nakgy8-TimeKeeper {
    width: 350px !important;
    text-align: center;
}

input:focus {
    outline: none;
}

/* .MuiSnackbar-root {
  background-color: red;
} */
/* 
.MuiSnackbarContent-root {
  background-color: red;
}
.MuiSnackbarContent-message {
  background-color: red;
}

.MuiSnackbarContent-action {
  background-color: red;
} */
.EasyMDEContainer .CodeMirror {
    color: #707070;
}

/* .rc-md-editor .editor-container .sec-html {
  display: none;
} */

.rdw-editor-wrapper {
    background-color: #fff;
}
/* 
.public-DraftStyleDefault-ltr {
  height: 100px;
} */

h1,
h2,
h3,
h4,
h5,
input,
button {
    font-family: ComfortaBold !important;
}
.parent-review-section .container {
    position: relative;
}
.parent-review-section .main-title {
    text-align: center;
    margin-bottom: 15px !important;
}
.parent-review-section .review-carousel {
    width: 100%;
    max-width: 940px;
    margin: 0 auto;
    height: auto;
    position: static;
}
.parent-review-section .review-carousel .review-carousel-inner {
    position: static !important;
}
.parent-review-section .left-btn {
    left: -50px !important;
    top: 50% !important;
}
.parent-review-section .right-btn {
    right: -50px !important;
    top: 50% !important;
}
.parent-review-section .review-carousel .review-carousel-wrapper {
    display: block;
}
.teacher-blog {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 30px 0;
}
.teacher-blog .img-blog {
    flex: 0 0 30%;
    max-width: 30%;
    padding: 0 30px 0 5rem;
}
.teacher-blog .img-blog img {
    max-width: 100%;
}
.teacher-blog .content-blog {
    flex: 0 0 70%;
    max-width: 70%;
    padding: 0 140px 0 30px;
}
.teacher-blog .content-blog .content-blog-inner {
    width: 100%;
    margin: 0;
}
@media (max-width: 1366px) {
    .parent-review-section .left-btn {
        left: -20px !important;
    }
    .parent-review-section .right-btn {
        right: -20px !important;
    }
    .teacher-blog .content-blog {
        padding: 0 30px;
    }
    .teacher-blog .img-blog {
        padding: 0 30px;
    }
}
@media (max-width: 991px) {
    .teacher-blog .img-blog {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }
    .teacher-blog .content-blog {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .teacher-blog .content-blog .content-blog-inner {
        text-align: left;
        align-items: flex-start;
    }
}
@media (max-width: 767px) {
    .main-title {
        font-size: 22px !important;
        margin: 0 !important;
    }
    .main-title.spacing-adjust {
        margin: 30px 0 15px !important;
    }
    .parent-review-section {
        padding: 30px 0;
        height: auto !important;
    }
    .teacher-blog .content-blog .content-blog-inner h3 {
        font-size: 30px;
        margin-bottom: 15px;
    }
    .teacher-blog .img-blog,
    .teacher-blog .content-blog {
        padding: 0 15px;
    }
    .badge-outer {
        margin: 25px 15px !important;
    }
    .badge-outer .badge-outer-wrapper {
        flex-flow: column !important;
    }
    .badge-outer .badge-outer-wrapper .badge-content {
        margin: 0 0 15px !important;
    }
}
@media (max-width: 575px) {
    .upcoming-section-outer .upcoming-section {
        display: flex;
        flex-wrap: wrap;
    }
    .upcoming-section-outer .upcoming-section .slide-list {
        width: 100%;
    }
    .upcoming-section-outer .upcoming-section .slide-list .card {
        width: 100%;
    }
    .blue-card-blog {
        width: 72px !important;
    }
    .parent-review-section .review-carousel {
        max-width: 380px;
    }
    .parent-review-section .left-btn {
        left: 0 !important;
    }
    .parent-review-section .right-btn {
        right: 0 !important;
    }
}
