.mainDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.formWrapper {
    border: 1px solid #83838b;
    border-radius: 21px;
    box-shadow: -4px 4px 4px #00000029;
    max-width: 25rem;
    padding: 1.75rem 1.875rem;
    margin: 0 1.8rem;
    background-color: #fff;
    margin-top: 8rem;
}

.heading {
    color: #88addb;
    font-size: 35px;
}

.inputWrapper {
    margin-top: 40px;
    margin-bottom: 10px;
}

.emailInput {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    border-radius: 21px 21px 0px 0px;
    width: 100%;
    padding: 10px 20px;
    color: #707070;
}

.emailInput::placeholder {
    color: #707070;
    font-size: 21px;
}

.passwordInput {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    border-radius: 0px 0px 21px 21px;
    width: 100%;
    padding: 10px 20px;
    color: #707070;
}

.passwordInput::placeholder {
    color: #707070;
    font-size: 21px;
}

.loginText {
    font-size: 16px;
    color: #707070;
    font-family: ComfortaBold !important;
}

.login {
    color: #88addb;
    cursor: pointer;
    margin-left: 5px;
}

.socialBtn {
    display: none;
    align-items: center;
    justify-content: center;
    margin: 30px 0px;
    color: #707070;
    p {
        margin-bottom: 0px;
        margin-left: 20px;
        margin-right: 20px;
        font-size: 18px;
    }
}

.continueBtn {
    padding: 10px;
    background-color: #88addb;
    color: #fff;
    text-align: center;
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #88addb;
    border-radius: 18px;
    cursor: pointer;
    font-size: 21px;
    font-family: ComfortaBold !important;
}

.para {
    font-size: 0.875em;
    color: #707070;
    text-align: justify;
    font-family: RobotoRegular;
    margin-bottom: 15px;
}

.radiobtn {
    display: flex;
    align-items: center;
    .para {
        margin-bottom: 0px;
    }
}

.inputCheckbox {
    width: 26px;
    height: 26px;
    margin-right: 10px;
}

@media only screen and (max-width: 460px) {
    .formWrapper {
        font-size: 0.9em;
        min-width: 20rem;
        padding: 1.75rem 1.5rem;
    }

    .heading {
        font-size: 1.7em;
    }

    .continueBtn,
    .para,
    .loginText {
        font-size: 0.9em;
    }
}
