.assets {
    width: 100%;
    height: 100%;
    border-radius: 24px;
}

.block {
    box-shadow: 3px 6px 6px #00000029;
    border: 1px solid #707070;
    border-radius: 24px;
    /* height: 26vh;
      max-width: 26vh; */
    height: 15vh;
    max-width: 8vw;
    width: 5vw;
    margin-bottom: 20px;
}
.rowDiv {
    position: fixed;
    bottom: 2vh;
    width: 100%;
}
/* 
.whiteboardLeft {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    border-right: 1px solid black;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    width: 50%;
    justify-content: center;
    display: block;
    padding: 0px;
    cursor: url("../../../../../../../assets/images/Clicker.svg"), pointer;
} */
/* 
.whiteboardRight {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    width: 50%;
    justify-content: center;
    display: block;
    padding: 0px;
    cursor: url("../../../../../../../assets/images/Clicker.svg"), pointer;
} */

@media only screen and (max-width: 1275px) {
    .block {
        display: none;
    }

    .studentsParticipantsList {
        display: none;
    }
}

@media only screen and (max-height: 698px) {
    .block {
        display: none;
    }

    .studentsParticipantsList {
        display: none;
    }
}

.centerText {
    margin-top: auto;
    margin-bottom: auto;
    height: max-content;
}
/* 
.whiteboardRow {
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 24px;
} */


.whiteboardRow {
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 24px;
}

.whiteboardLeft {
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    width: 50%;
    justify-content: center;
    align-items: flex-end;
    display: flex;
    padding: 0px;
    flex-direction: column;
}

.whiteboardRight {
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    width: 50%;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    padding: 0px;
    flex-direction: column;
}

.wbContainerRight {
    border-radius: 0 25px 25px 0;
    width: 100%;
    max-width: 617px;
    border: 1px solid #707070;
    background: #fff;
    cursor: url("../../../../../../../assets/images/Clicker.svg"), pointer;
}

.wbContainerLeft {
    border-radius: 25px 0 0 25px;
    width: 100%;
    max-width: 617px;
    border: 1px solid #707070;
    background: #fff;
    border-right: 0;
    cursor: url("../../../../../../../assets/images/Clicker.svg"), pointer;
}

.participantsList {
    height: 78vh;
    width: 10vw;
    position: absolute;
    right: 0;
    top: 10vh;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
}


.moreParticipantBoxesCol {
    position: relative;
    top: 30px;
}


.lessParticipantBoxesCol {
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 10px;
}