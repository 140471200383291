.waiting_content__container {
    height: 50vh;
    width: 482px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 35px;
    color: #707070;
    text-align: center;
    margin: auto;
}

.waiting_content__container video {
    height: 8rem;
}
