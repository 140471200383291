.newMessageComponent {
    border: 1px solid #bbbebe;
    border-radius: 15px;
    padding: 0.507rem 1rem;
    display: flex;
    align-items: center;
    min-height: 67px;
    margin-bottom: 1rem;
    margin-left: 2rem;
    margin-right: 1rem;

    .newMessageInput {
        border: none;
        outline: none;
        flex: 1;
        width: 100%;
        display: flex;
        align-items: center;
        color: #707070;
        margin-right: 1rem;
        word-break: break-word;
        cursor: text;
    }

    .newMessageInput::placeholder {
        color: #bbbebe;
        opacity: 1; /* Firefox */
    }

    .newMessageInput:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #bbbebe;
    }

    .newMessageInput::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #bbbebe;
    }

    .circularProgressContainer {
        flex: 1;
        text-align: center;
    }

    .disabledUserMessageText {
        margin: auto;
        user-select: none;
        pointer-events: none;
    }

    .sendFileIconContainer {
        margin-right: 1rem;
        color: #bbbebe;
        cursor: pointer;

        .sendFileIcon {
            font-size: 35px;
        }
    }

    .sendMessageIconContainer {
        background-color: #88addb;
        border-radius: 100%;
        color: #ffffff;
        height: 3.3rem;
        width: 3.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .sendMessageIcon {
            font-size: 35px;
        }
    }
}
