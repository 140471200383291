.settingsWrapper {
    width: 450px;
    background-color: #c8e8e5;
    padding: 15px;
    border-radius: 20px;
    display: flex;
    height: 100%;
}

.settingLeft {
    min-width: 290px;
}

.fullWidth {
    width: 100%;
}

.settingsType {
    width: 250px;
    display: flex;
    justify-content: space-between;
    p {
        cursor: pointer;
        // color: #707070;
    }
}

.activeType {
    color: #e36b2d;
}

.inactive {
    color: #707070;
}

.accessBlock {
    display: flex;
    //   justify-content:
}

.participant {
    width: -webkit-fill-available;
    margin-right: 1rem;

    p {
        margin: 4px 0;
        font-family: "RobotoMediumItalic";
        color: #707070;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.pencolor {
    width: 20px;
    height: 20px;
    background-color: black;
    border-radius: 50%;
    margin-bottom: 8px;
    margin-top: 6px;
}

.pencolorRight {
    width: 23px;
    height: 23px;
    background-color: black;
    border-radius: 50%;
    margin-bottom: 8px;
    cursor: pointer;
}

.button {
    height: 32px;
    border-radius: 50px;
    padding: 4px;
    cursor: pointer;
    width: 50%;
    box-shadow: 4px 4px 4px #00000029;
    border: 1px solid #83838b;
    margin: auto;
}

.circleRight {
    height: 23px;
    width: 23px;
    background-color: #bbbebe;
    border-radius: 50%;
    float: right;
}

.circleLeft {
    height: 23px;
    width: 23px;
    background-color: #88addb;
    border-radius: 50%;
    float: left;
}

.grantAccess {
    border: 1px solid #707070;
    border-radius: 21px;
    padding: 10px 5px;
    margin-bottom: 10px;
    background-color: #fff;
    font-family: "RobotoMediumItalic";
    p {
        font-size: 13px;
        text-align: center;
        margin-bottom: 0px;
        color: #707070;
    }
}

.resetTeams {
    border: 1px solid #707070;
    border-radius: 21px;
    padding: 20px 5px;
    background-color: #fff;
    text-align: center;
    font-family: "RobotoMediumItalic";
    p {
        font-size: 13px;
        margin-bottom: 0px;
        color: #707070;
    }
    .resetBtn {
        border: 1px solid #83838b;
        display: inline;
        padding: 5px;
        border-radius: 10px;
        cursor: pointer;
    }
}

.settingRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.grantColorType {
    display: flex;
    justify-content: space-around;
    font-family: "RobotoMediumItalic";
    p {
        cursor: pointer;
    }
}

.colorGrid {
    display: flex;
    justify-content: space-around;
    .pencolor {
        margin-right: 10px;
        cursor: pointer;
    }
}

.grantColor {
    text-align: center;
}

.randomBtn {
    text-align: center;
    border: 1px solid #707070;
    background-color: #fff;
    display: inline;
    padding: 3px 7px;
    border-radius: 10px;
    cursor: pointer;
    font-family: "RobotoMediumItalic";
    color: #707070;
}

.imgPosition {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.positionBtn {
    border: 1px solid #707070;
    border-radius: 15px;
    font-size: 15px;
    background-color: #fff;
    padding: 1px 6px;
    cursor: pointer;
}

.imgDragText {
    font-size: 10px;
    color: #707070;
}

.activeColorType {
    color: #e36b2d;
}

.imageWrapper {
    position: relative;
    border: 1px solid #707070;
    min-height: 150px;
    border-radius: 21px;
    box-shadow: -4px 4px 4px #00000029;
}

.studentAccess {
    cursor: pointer;
}

.arrowWrapper {
    position: relative;
}
