.container {
    width: 100%;
    /* z-index: 5; */
}

.inputContainer {
    border-radius: 26px;
    border: 1px solid #83838b;
    box-shadow: 4px 4px 4px #00000029;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    /* background-color: #fff; */
    background-color: #f3f3f3;
    z-index: -10 !important;
    padding: 5px;
}

.inputContainerDateTime {
    border-radius: 26px;
    border: 1px solid #83838b;
    box-shadow: 4px 4px 4px #00000029;
    justify-content: space-between;
    align-items: center;
    background-color: #f3f3f3;
    z-index: -10 !important;
    max-width: 38rem;
    word-wrap: break-word;
    overflow-wrap: break-word !important;
    display: flex;
    padding: 5px;
}

.greaterData {
    height: 80px;
}

.lessData {
    height: 50px;
}

.container .inputsection {
    border-radius: 26px;
    border: none;
    width: calc(100% - 30px);
    height: 40px;
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    font-family: RobotoMediumItalic;
    color: #707070;
    font-size: 15px;
    padding-left: 10px;
    white-space: normal;
}

.container .inputsectionDateTime {
    border-radius: 26px;
    border: none;
    width: calc(100% - 30px);
    height: 3rem;
    line-height: 25px;
    white-space: nowrap;
    overflow: hidden;
    font-family: RobotoMediumItalic;
    color: #707070;
    font-size: 15px;
    padding-left: 10px;
    white-space: normal;
    display: flex;
    align-items: center;
}

.dropicon {
    background-color: #88addb;
    color: #fff;
    height: 30px;
    border-radius: 50%;
}

.feeCurrency {
    color: #fff;
    width: 21px;
    background-color: #88addb;
    border-radius: 30px;
    height: 21px;
    display: flex;
    justify-content: center;
    margin-left: 3%;
}

.feeAmount {
    width: 75%;
}

.feeAmount input {
    width: 70%;
    border: 0;
    margin-left: 8%;
    background-color: #f3f3f3;
}

/* Chrome, Safari, Edge, Opera */

.feeAmount input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

/* Firefox */
.feeAmount input[type="number"] {
    -moz-appearance: textfield;
}

.dropicon.dropdownopen {
    background-color: #bbbebe;
}

@media (max-width: 600px) {
    .inputContainer {
        height: 35px;
    }

    /* .dropicon {
    height: 20px;
  } */
}

.dateTimeInput {
    background-color: rgb(200, 232, 229);
    border-radius: 20px;
    padding-bottom: 20px;
    width: 38rem !important;
    position: absolute !important;
    left: 0 !important;
}
