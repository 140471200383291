.gameArea {
    width: 100%;
    height: 100%;
    border-radius: 24px;
    display: flex;
}

.shapesDiv {
    width: 30%;
    height: 100%;
    background: #e9f3fb;
    border-radius: 24px 0 0 24px;
    cursor: pointer;
}

.shapesDropArea {
    width: 70%;
    height: 100%;
    border-radius: 0 24px 24px 0;
    cursor: pointer;
}

.trash {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    font-size: 32px;
    left: 27%;
    bottom: 3%;
    z-index: 1;
}

.gameControls {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 18%;
    top: 0;
    background: #fdd7c6 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px 0px 26px 26px;
    padding: 10px;
    width: 50px;
}

.gameControl {
    height: 28px;
    width: 28px;
    color: white;
    margin: 5px auto;
    cursor: pointer;
}

//shapes

.rect {
    background-color: #2e70ff;
    position: absolute;
}

.triup {
    border-bottom: 75px solid #edc951;
    border-left: 37.5px solid transparent;
    border-right: 37.5px solid transparent;
    position: absolute;
}

.tridown {
    border-top: 37.5px solid #edc951;
    border-left: 18.75px solid transparent;
    border-right: 18.75px solid transparent;
    position: absolute;
}

.circle {
    border-radius: 50%;
    background: #d94e43;
    position: absolute;
}

.semicircle {
    width: 75px;
    height: 37.5px;
    border-top-left-radius: 37.5px;
    border-top-right-radius: 37.5px;
    border-bottom: 0;
    position: absolute;
}
