.videoSection,
.studentVideoSection {
    width: 100%;
    object-fit: fill;
}

.videoImg {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 30px;
}

.controlSection {
    bottom: 0;
    position: absolute;
    /* left: 15px;
  right: 7px; */
    transition: 0.3s ease;
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 23px;
    overflow: hidden;
}

.controlIcons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.crossIcon {
    position: absolute;
    right: 20px;
    top: 13px;
    color: #e36b2d;
    cursor: pointer;
}

.modalContent {
    border-radius: 1rem !important;
}

.modalBody {
    margin: 4rem 2rem 2rem;
    border: 1px solid;
    border-radius: 1rem;
    text-align: center;
}

.button_div {
    text-align: center;
}

.end_call_btn {
    background: #e36b2d;
    color: #fff;
    border-radius: 2rem;
    padding: 0.7rem 2.3rem;
    margin-bottom: 1rem;
}

.controlDiv {
    background: white;
    opacity: 0.9;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    flex-flow: column;
    padding: 10px;
    padding-top: 5px;
}

.studentName {
    text-align: center;
    font-size: 1.1rem;
    font-weight: bolder;
}

.controlImgLeft {
    cursor: pointer;
    z-index: 9999;
    width: 33px;
}

.controlImgRight {
    cursor: pointer;
    z-index: 9999;
    width: 30px;
    height: 25px;
}

.mutedImg {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: block;
    width: 30px;
}

.endcallModal {
    position: absolute;
}
