.previewContainer {
    height: 100vh;
    width: 100vw;
}

.closeButton {
    position: absolute;
    top: 3%;
    right: 9.5%;
    color: #ffffff;
    padding: 0.5rem 1.5rem;
    background-color: #e36b2d;
    border-radius: 26px;
    cursor: pointer;
    z-index: 999;
}

.switchWrapper {
    margin: 35px 0;
}

.switch {
    margin-bottom: 10px;
    padding: 10px 10px 10px 20px;
    border-radius: 30px 0px 0px 30px;
    position: absolute;
    right: 0px;
}

.switch p {
    margin: 0px;
    color: #707070;
    font-size: 25px;
}

.switchContainer {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 5;
}
.peviewContainer {
    height: 90vh;
    width: 90vw;
    position: absolute;
    top: 2%;
    right: 5%;
    border: 1px solid #83838b;
    border-radius: 26px;
}