.inputBox {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    border-radius: 21px;
    width: 81%;
    padding: 10px;
    margin-bottom: 2%;
    margin-top: 2%;
    padding-left: 15px;
    height: 44px;
}

.countryBox {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    border-radius: 21px;
    width: 100%;
    padding: 10px;
    margin-bottom: 2%;
    margin-top: 2%;
    padding-left: 15px;
    height: 44px;
}

.introduceBox {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    border-radius: 21px;
    width: 81%;
    padding: 10px;
    margin-bottom: 2%;
    margin-top: 2%;
    padding-left: 15px;
}

.toolText {
    margin: 0px;
    color: #ebebeb;
    font-weight: 700;
    font-size: 15px;
    border-radius: 0;
    font-family: ComfortaBold !important;
}

.middleInput {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    padding: 10px;
    padding-left: 15px;
    color: #707070;
}

.fileupload {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 21px;
    width: 100%;
    padding: 5px 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 41px;
    background-color: #fff;
}

.imageName__container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 80%;
    margin-top: 5px;
    margin-left: 10px;
}

.imageName_sub_container {
    white-space: nowrap;
    font-size: 17px;
    margin-right: 10px;
}

.imageName_buttons_container {
    color: #88addb;
    cursor: pointer;
}
.imageName_buttons_container span {
    margin: 0 11px;
    font-size: 17px;
}

.uploadModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8798797979879;
    width: 100vw;
    height: 100vh;
    display: flex;
}
.uploadModalContainer {
    background-color: #f3f3f3;
    border-radius: 21px;
    box-shadow: 4px 4px 4px #00000029;
    padding: 30px 35px;
    margin: auto;
    max-width: 80vw;
    max-height: 80vh;
}

.crossIcon {
    position: relative;
    left: 100%;
    color: #e36b2d;
    cursor: pointer;
    top: -2%;
}

.previewImage__container {
    max-height: 90vh;
    display: flex;
}
.previewImage__container img {
    max-height: 70vh;
    margin: auto;
}
.uploadIcon {
    background-color: #88addb;
    color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
}

.fileInput {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    border-radius: 21px;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    display: none;
}

.detailsContainer {
    border-radius: 21px;
}

.heading {
    font-family: Comforta;
    color: #88addb;
    font-size: 25px;
    margin-bottom: 5%;
}

.title {
    font-family: Comforta;
    font-size: 21px;
    color: #707070;
    margin-left: 3%;
    margin-bottom: -1%;
    margin-top: 2%;
}

.studentScroll {
    overflow-x: auto;
    overflow-y: auto;
    height: 65vh;
    width: 100%;
}

.studentScroll::-webkit-scrollbar {
    height: 5px;
    width: 7px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}

.studentScroll::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.buttonWrapper {
    display: flex;
    margin-bottom: 10%;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.buttonWrapper img {
    width: 120px;
    cursor: pointer;
}

@media screen and (max-width: 380px) {
    .buttonWrapper {
        display: flex;
        margin-bottom: 10%;
        flex-wrap: wrap;
        align-items: center;
    }
}

.inputBox::-webkit-input-placeholder {
    font-size: 20px;
    margin-left: 20px;
}

.detailText {
    font-size: 0.9rem;
    margin-left: 2%;
}

.introduceBox:focus {
    outline: none !important;
    border-color: #83838b;
}

.countryBox:focus {
    outline: none !important;
    border-color: #83838b;
}

.bottomInput {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    border-radius: 0px 0px 21px 21px;
    width: 100%;
    padding: 10px;
    color: #707070;
}

.arrows {
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
        white no-repeat calc(98% - 8px) !important;
    -webkit-appearance: none;
}
