.previewContainer {
    height: 90vh;
    width: 90vw;
    position: absolute;
    top: 3%;
    right: 5%;
    border: 1px solid #83838b;
    border-radius: 26px;
}

.closeButton {
    position: absolute;
    top: 2%;
    right: 9.5%;
    color: #ffffff;
    padding: 0.5rem 1.5rem;
    background-color: #e36b2d;
    border-radius: 26px;
    cursor: pointer;
    z-index: 999;
}
