.chat_details__container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    width: calc(100vw - 18rem);

    .recipientProfilePhotoContainer {
        height: 2.5rem;
        width: 2.5rem;

        .recipientProfilePhoto {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            border-radius: 100%;
        }

        span {
            background-color: #f8d7c6;
            color: #ffffff;
            user-select: none;
        }
    }

    .userName {
        font-family: ComfortaBold;
        font-size: 1.5em;
        color: #012b4a;
        margin-left: 0.8rem;
        cursor: pointer;
        text-decoration: underline;
        flex: 1;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }
}

@media screen and (max-width: 865px) {
    .chat_details__container {
        width: 100vw;
    }
}
