.rdp-picker {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    display: flex;
    user-select: none;
    height: 105px;
    background-color: white;
    border: 1px solid #ccc9c9;
    border-radius: 15px;
    width: 200px;
}

@media (max-width: 400px) {
    .rdp-picker {
        width: 250px;
    }
}

.rdp-masked-div {
    color: black;
    overflow-y: auto;
    cursor: pointer;
    height: 105px;
    background-color: #c8e8e5;
    position: relative;
    mask-image: linear-gradient(
        transparent,
        10%,
        rgba(0, 0, 0, 1),
        90%,
        transparent
    );
    -webkit-mask-image: linear-gradient(
        transparent,
        10%,
        rgba(0, 0, 0, 1),
        90%,
        transparent
    );
}

.rdp-column-container {
    flex-grow: 1;
    display: inline-block;
    touch-action: none;
}

.rdp-column {
    position: absolute;
    z-index: 0;
    width: 100%;
    top: 2rem;
}

.rdp-reticule {
    border: 0;
    border-top: 2px solid rgba(109, 202, 236, 1);
    height: 2px;
    position: absolute;
    width: 80%;
    margin: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.rdp-text-overlay {
    position: absolute;
    /* font-style: italic; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    font-size: 20px;
    color: white;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.rdp-text-overlay div {
    font-size: 12px;
    position: relative;
    top: 1px;
    left: 1px;
    color: gray;
    width: 0px;
    font-style: italic;
}

.rdp-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    font-size: 20px;
}
