.block {
    box-shadow: 3px 6px 6px #00000029;
    border: 1px solid #707070;
    border-radius: 25px;
    height: 26vh;
    width: calc(33% - 80px);
    margin: 20px 40px;
}

.switchWrapper {
    position: absolute;
    top: 40%;
    right: 0px;
}

.switch {
    margin-bottom: 10px;
    padding: 10px 10px 10px 20px;
    border-radius: 30px 0px 0px 30px;
}

.switch p {
    margin: 0px;
    color: #707070;
    font-size: 25px;
}

.buttonWrapper {
    position: absolute;
    width: 100%;
    bottom: 0px;
    /* opacity: 0; */
}

.btn {
    padding: 10px 17px 10px 58px;
    background-color: #ebebeb;
    border-radius: 30px 30px 0px 00px;
}

.btn p {
    margin: 0px;
    color: #88addb;
    font-size: 25px;
}

.icon {
    margin: 0px 6px;
    width: 55px;
}

.icon:hover {
    border: 1px solid #707070;
    border-radius: 50%;
}

.line {
    margin-top: 0px;
    margin-bottom: 0px;
    border-top: 4px solid #707070;
}

.btnText {
    display: flex;
    align-items: center;
}

.screenArrangementWrapper {
    position: absolute;
    top: -170px;
    left: -80px;
    background-color: #fff;
    width: 200px;
    height: 160px;
    padding: 10px;
    box-shadow: 4px 4px 4px #00000029;
    border-radius: 21px;
}

.screenArrangementScroll {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}

.screenArrangementScroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}

.screenArrangementScroll::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.arrangementTab {
    display: flex;
    justify-content: space-around;
}

.arrangementTab p {
    font-size: 15px;
    border: 1px solid #a7c0db;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
}

.imageSection img {
    margin-top: 3px;
    margin-bottom: 3px;
}

.grid {
    display: flex;
    flex-flow: row wrap;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}
