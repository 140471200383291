.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 135px;
}

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 865px;
    padding: 0 20px;
    padding-bottom: 25px;
}

.heading {
    color: #88addb;
    font-size: 28px !important;
    font-family: ComfortaBold;
    margin-bottom: 20px;
}

.chooseHead {
    color: #88addb;
    font-size: 18px;
    font-family: ComfortaBold;
    margin-bottom: 10px !important;
    cursor: pointer;
}

.planHead {
    font-size: 25px !important;
    font-family: ComfortaBold;
    margin-bottom: 10px;
    color: #13385c;
}

.planList {
    list-style-position: outside;
}

li::before {
    display: none !important;
}

li {
    font-size: 18px !important;
    text-align: start;
}

@media screen and (max-width: 865px) {
    .mainContainer {
        margin-top: -65px !important;
    }
}

@media screen and (max-width: 453px) {
    .heading {
        font-size: 23px;
    }
    .chooseHead {
        font-size: 16px;
    }
    .planHead {
        font-size: 20px;
    }
    .planList {
        margin-left: -10px !important;
    }
    li {
        font-size: 13px;
    }
}

@media screen and (max-width: 320px) {
    .heading {
        font-size: 20px !important;
    }
    .chooseHead {
        font-size: 14px !important;
    }
    .planHead {
        font-size: 16px !important;
    }
    li {
        font-size: 12px !important;
    }
}
