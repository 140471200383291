.row {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #f3f3f3;
}

.leftsection {
    padding: 8vh 0px 8vh 50px;
    height: 100vh;
    z-index: 10;
}

.crossImg {
    cursor: pointer;
}

.italicPara {
    font-family: RobotoMediumItalic;
    color: #707070;
    margin-bottom: -3px;
}

.tabIcon {
    width: 40px;
    height: 40px;
    background-color: #92d1ea;
    box-shadow: 3px 3px 6px #00000029;
    padding: 7px;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
}

.selectedTool {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #83838b;
    border-radius: 26px;
    margin-right: 20px;
    box-shadow: 4px 4px 4px #00000029;
    font-family: ComfortaBold;
    color: #707070;
    width: 250px;
    padding: 8px 10px 8px 20px;
    margin: auto;
}

.selectedToolSection {
    display: flex;
}

.toolText {
    margin: 0px;
    margin-right: 20px;
    color: #707070;
    font-size: 20px;
}

.toolSection {
    background-color: #c8e8e5;
    width: 250px;
    margin: auto;
    border-radius: 10px;
    padding: 5px 20px;
}

.toolType {
    cursor: pointer;
    color: #707070;
    font-family: RobotoMediumItalic;
    font-size: 17px;
    margin: 0px;
}

.downAngleIcon {
    background-color: #88addb;
    color: #fff;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
}

.iconsection {
    border: 2px solid #dfe1e1;

    border-bottom: none;
    /* border-radius: 21px; */
    /* border-bottom-left-radius: none; */
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;
    /* background-color: #fff; */
    background-color: #f3f3f3;
    box-shadow: 0px -3px 8px -3px rgba(0, 0, 0, 0.16);
}

.dragText {
    color: #707070;
    font-family: ComfortaBold;
    font-size: 17px;
}

.heading {
    font-family: ComfortaBold;
    font-size: 25px;
    color: #707070;
    margin-top: 5px;
}

.leftSection {
    border: 2px solid #dfe1e1;
    box-shadow: 4px 4px 4px #00000029;
    padding: 20px;
    border-radius: 21px;
    border-top-left-radius: 0px;
    height: 550px;
    /* background-color: #fff; */
    background-color: #f3f3f3;
    margin-top: -2px;
}

.leftScroll {
    height: 100%;
    overflow: hidden;
    /* overflow-y: scroll; */
    padding-right: 30px;
}

/* .leftScroll::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: #fff;
  border: 1px solid rgb(131, 131, 139);
  border-radius: 50px;
}

.leftScroll::-webkit-scrollbar-thumb {
  background: #88addb;
  border-radius: 50px;
} */

.sessionblock {
    display: flex;
    padding: 3vh 10px;
    align-items: center;
    justify-content: space-between;
}

.crosssection {
    display: flex;
    align-items: center;
}

.playicon {
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
}

.plusicon {
    color: gray;
    background-color: #dfe1e1;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    padding: 5px;
    margin-top: 70px;
    cursor: pointer;
}

.rightSection {
    text-align: center;
}

.logoImg {
    position: fixed;
    width: 39vw;
    right: -9vw;
    top: -18vw;
}

.logoText,
.logoSubText {
    text-align: center;
    font-family: ComfortaBold;
    font-size: 2.8vw;
    line-height: 67px;
    letter-spacing: 0.3px;
    color: #ffffff;
    position: fixed;
    top: 1.2vw;
    right: 1.8vw;
    margin-bottom: 0;
}

.logoSubText {
    font-size: 2.2vw;
    top: 4.4vw;
    right: 5.9vw;
}

.rightTime {
    position: absolute;
    top: 8vw;
    right: 3vw;
    font-family: RobotoMedium;
    font-size: 30px;
    background-color: #e9f3fb;
    color: #707070;
    padding: 0.5vw 2.5vw;
    border-radius: 25px;
}

@media (max-width: 1200px) {
    .logoImg {
        position: fixed;
        width: 43vw;
        right: -10vw;
        top: -19vw;
    }

    .rightTime {
        top: 10vw;
        right: 6vmax;
        font-size: 3vw;
        border-radius: 4vmax;
        padding: 4px 20px;
    }
}

@media (max-width: 600px) {
    .logoImg {
        width: 50vw;
        right: -9vw;
        top: -18vw;
    }

    .rightTime {
        top: 16vw;
        left: 1vw;
        right: 9vw;
        font-size: 20px;
        padding: 0vh 3vw 0vh 2vw;
        border-radius: 4vw;
    }
}

.time {
    font-family: RobotoMedium;
    font-size: 30px;
    background-color: #e9f3fb;
    color: #707070;
    display: inline-block;
    padding: 5px;
    border-radius: 15px;
}

.rightTabSection {
    position: absolute;
    left: -20px;
    top: 250px;
    z-index: -5;
}

.rightTab {
    background-color: #e9f3fb;
    border-radius: 20px;
    width: 160px;
    color: #707070;
    margin-bottom: 18px;
    font-family: ComfortaBold;
    font-size: 30px;
    padding: 5px;
}
