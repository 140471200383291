.logoDiv {
    position: fixed;
    left: -10rem;
    top: -16rem;
    z-index: 1;
    cursor: pointer;
}

.logoContainer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}

.studentLogoBg {
    width: 22.5rem;
    position: fixed;
}

.logoText,
.logoSubText {
    text-align: center;
    font-family: ComfortaBold;
    font-size: 37px;
    line-height: 67px;
    letter-spacing: 0.3px;
    color: #ffffff;
    position: fixed;
    top: 2.3rem;
    left: 3rem;
    margin-bottom: 0;
}

.logoSubText {
    font-size: 21px;
    top: 4.9rem;
    left: 3.6rem;
}

.logoImg {
    width: 38rem;
}

.parentWrapper {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
}

.outerBlock {
    padding: 20px;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
}

.wrapper {
    border: 1px solid #707070;
    border-radius: 25px;
    box-shadow: -4px 4px 4px #00000029;
    height: 93vh;
    position: relative;
    background-color: #ffffff;
    padding: 10vh 2vw 10vh 5vw;
}

.classDetails {
    box-shadow: -4px 4px 4px #00000029;
    border-radius: 21px;
    background-color: #e9f3fb;
    padding: 3vh;
}

.registerButton {
    padding: 13px 18px;
    font-size: 30px;
    color: #fff;
    background-color: #13385c;
    font-family: ComfortaBold;
    cursor: pointer;
    width: 13vw;
    justify-content: center;
    display: flex;
    box-shadow: 4px 4px 4px #00000029;
    border-radius: 32px;
    position: absolute;
    right: 6vw;
}

.content {
    top: 30%;
    position: absolute;
}

.heading {
    color: #13385c;
    font-family: ComfortaBold;
    font-size: 35px;
    margin-bottom: 7vh;
}

.description {
    font-family: RobotoMediumItalic;
    letter-spacing: 0.11px;
    color: #707070;
    font-size: 22px;
    line-height: 25px;
    margin-bottom: 7vh;
    height: 21vh;
}

@media only screen and (max-width: 1409px) {
    .description {
        margin-bottom: 0;
    }
}

.time {
    font-family: RobotoMediumItalic;
    letter-spacing: 0.15px;
    color: #e36b2d;
    font-size: 30px;
    line-height: 35px;
}

.timeText {
    margin: 0;
}

.descriptionText {
    margin: 1vh;
}

.studentDetails {
    font-family: RobotoMediumItalic;
    letter-spacing: 0.11px;
    color: #707070;
    font-size: 22px;
    line-height: 25px;
}

.starsImg {
    width: 25vw;
    position: fixed;
    right: 1.3rem;
    top: 1.3rem;
    z-index: 1;
}

.classImg {
    width: 18vw;
    border-radius: 21px;
    z-index: 2;
}

.classImgs {
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.classImgsDiv {
    height: 30vh;
    width: 20vw;
}

.price {
    font-size: 99px;
    color: #88addb;
    font-family: ComfortaBold;
    text-align: right;
    margin: 0;
    line-height: 110px;
    margin-top: 3vh;
}

.rightCol {
    padding: 0 6vw;
}

.leftCol {
    padding-top: 1vh;
}

.coverImage {
    width: 100%;
    height: 100%;
    border-radius: 2vw;
    z-index: 2;
    position: relative;
}

.leftArrow {
    color: #83838b;
    width: 30px;
    position: absolute;
    cursor: pointer;
    left: 4vw;
    z-index: 2;
}

.rightArrow {
    color: #83838b;
    width: 30px;
    position: absolute;
    cursor: pointer;
    right: 4vw;
    z-index: 2;
}

.descriptionSpan p {
    display: inline;
}
