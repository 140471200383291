#container {
    height: 100%;
}

#topRow {
    height: 100%;
}

#leftCol {
    background-color: #d0e9ea;
    padding-bottom: 2vh;
    padding-top: 10px;
}

#leftColStudent {
    background-color: #d0e9ea;
    padding: 2vh 9vh 4vh 10vh;
}
#leftColStudentMaze {
    background-color: #d0e9ea;
    padding: 30px;
}

#rightCol {
    background-color: #f3f7e4;
}
#rightColStudent {
    display: none;
}

#rightColHead {
    display: flex;
    /* padding-top: 65px; */
    padding-top: 10px;
    justify-content: center;
}

#plassTitle {
    color: #a5d8ed;
    font-weight: bold;
}

#plassBox {
    margin-left: 20px;
    border: 2px solid rgb(165, 216, 237);
    border-radius: 5px;
    height: 40px;
    width: 50px;
    position: relative;
}

#plassPlus {
    color: rgb(165, 216, 237);
    position: absolute;
    right: 0px;
    top: 0px;
    line-height: 23px;
    font-weight: bold;
    font-size: 35px;
}

.plassTopMargin {
    margin-top: 15.81px;
}

#timer {
    background-color: #f7f2f2;
    color: #219bb4;
    width: 75%;
    margin: auto;
    margin-bottom: 6vh;
    /* height: 73px; */
    height: 9vh;
    line-height: 73px;
    font-size: 40px;
    text-align: center;
    font-weight: 800;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

#navigationContainer {
    margin-right: -15px;
    /* margin-top: 90px; */
}

#plassLogo {
    width: 85%;
    margin-bottom: 20px;
}

#plassStudentLogoDiv {
    text-align: right;
    background-color: #d0e9ea;
    padding-top: 4vh;
    padding-right: 40px;
}
#plassTeacherLogoDiv {
    display: none;
}

.teacherControls {
    position: fixed;
    bottom: 4vh;
}
#layoutsTab {
    display: flex;
    margin-top: 52px;
}

#popupDiv {
    display: flex;
    justify-content: space-between;
}
.buttonInActive:focus {
    outline: none;
}
.buttonActive:focus {
    outline: none;
}
.buttonInActive {
    width: 43%;
    background-color: transparent;
    height: 40px;
    font-family: CatalinaClementeRegular;
    margin-bottom: 15px;
    border: 1px solid #219bb4;
    color: #219bb4;
    border-radius: 4px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.buttonActive {
    width: 43%;
    border: none;
    background-color: #219bb4;
    height: 40px;
    font-family: CatalinaClementeRegular;
    margin-bottom: 15px;
    border-color: #219bb4;
    color: #f3f7e4;
    border-radius: 4px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popoverBasic {
    width: 263px !important;
    height: 488px;
    background-color: #f8f9fa;
}

.screens > img {
    width: 227px;
    height: 132px;
    cursor: pointer;
    margin-top: 7px;
}

#selectedScreen {
    border: 6px solid #fabf5e;
    border-radius: 22px;
}

#popupImage {
    width: 150px;
    margin-top: 50px;
}

#rightColIcons {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 4vh;
    width: 16.16%;
}

.gameRow {
    margin-top: 10px;
}

.gameRow img {
    height: 100%;
    cursor: pointer;
}

.gameControls {
    display: flex;
    flex-direction: column;
    float: left;
}

.gameControls > img {
    height: 60px;
    cursor: pointer;
}
#gameTimer {
    position: absolute;
    display: flex;
    right: 10px;
    background: #f7f2f2;
    border-radius: 50%;
    width: 125px;
    height: 125px;
    border: 3px solid #fabf5e;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    top: 20px;
}

#gameTimer > p {
    margin: 0px;
    padding: 0px;
}

#gameClock {
    color: #219bb4 !important;
    font-size: 30px;
    font-weight: 800;
}

.mazeControls {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2vh;
}
.mazeButtons {
    color: #7b7b78;
    width: 25%;
    cursor: pointer;
    text-align: center;
    background-color: #ffffff;
    border: 1px solid #706f6f;
    border-radius: 7px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.mazeButtonsActive {
    color: #7b7b78;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    background-color: #fabf5e;
    border: 1px solid #706f6f;
    border-radius: 7px;
    height: 50px;
    font-size: 20px;
}

/* effects css */

.effectsPopover {
    width: 200px !important;
    height: 400px;
    background-color: #f8f9fa;
    display: flex;
    overflow: auto;
}

#bar {
    width: 4px;
    background-color: #fabf5e;
    border-radius: 3px;
    float: left;
    margin-right: 5px;
    height: 838px;
}
.effectsButton {
    display: flex;
    justify-content: space-around;
}

.buttonInActive {
    width: 60%;
    background-color: transparent;
    height: 40px;
    font-family: CatalinaClementeRegular;
    margin-bottom: 8px;
    border: 1px solid #fabf5e;
    border-radius: 4px;
    color: black;
}
.buttonInActive:focus {
    outline: none;
}
.buttonActive {
    width: 60%;
    border: none;
    background-color: #fabf5e;
    height: 40px;
    font-family: CatalinaClementeRegular;
    margin-bottom: 8px;
    border: 1px solid #fabf5e;
    color: black;
    border-radius: 4px;
}

.buttonActive:focus {
    outline: none;
}

.sounds {
    background-color: #d0e9ea;
    height: 70px;
    width: 50px;
    margin: 5px;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
    cursor: pointer;
    font-family: CatalinaClementeRegular;
    display: flex;
    align-items: center;
    justify-content: center;
}
.effects {
    background-color: #d0e9ea;
    height: 70px;
    width: 50px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0px !important;
}

.effects > img {
    max-width: 100%;
    max-height: 100%;
    display: block;
}

.individualGraphic {
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    font-family: CatalinaClementeRegular;
    border: 1px solid #fabf5e;
    height: 50px;
    word-break: break-all;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flipContainer {
    height: 15vh;
    width: 15vh;
}

.layout2Participant {
    background: #f7f2f2 0% 0% no-repeat padding-box;
    height: 20vh;
    border-radius: 24px;
    opacity: 1;
    overflow: hidden;
}
