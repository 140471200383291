.inputWrapper {
    border: 1px solid #83838b;
    box-shadow: 4px 4px 4px #00000029;
    border-radius: 26px;
}

.customInput {
    border: none;
    background-color: #f3f3f3;
    width: calc(100% - 30px);
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.dropicon {
    background-color: #88addb;
    color: #fff;
    height: 30px;
    border-radius: 50%;
}

.dropicon.dropdownopen {
    background-color: #bbbebe;
}

.delete {
    float: right;
}

.item {
    display: flex;
    /* justify-content: space-between; */
    cursor: pointer;
    margin: 0px;
    margin-bottom: 5px;
    color: #fff;
}

.item span {
    width: 80%;
}

.customeDropContainer {
    position: absolute;
    top: 41px;
    left: 0px;
    z-index: 2;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: space-between;
    background-color: #88addb;
    border-radius: 26px;
    padding: 10px;
}
