.block {
    box-shadow: 3px 6px 6px #00000029;
    border: 1px solid #707070;
    border-radius: 24px;
    height: 26vh;
    max-width: 8vw;
    margin-bottom: 20px;
}

.audio {
    width: 100%;
    height: 100%;
    display: none;
}

.video {
    object-fit: contain;
}

.assets {
    width: 100%;
    height: 100%;
    border-radius: 24px;
}

.image {
    object-fit: contain;
}

.rowDiv {
    position: absolute;
    bottom: 0px;
    width: 100%;
}
