.container {
    height: 80%;
}

.studentScroll {
    overflow-x: auto;
    overflow-y: auto;
    height: 63vh;
    width: 87%;
    padding-right: 4%;
}

.studentScroll::-webkit-scrollbar {
    height: 5px;
    width: 7px;
    background: #fff;
    border: 1px solid rgb(131, 131, 139);
    border-radius: 50px;
}

.studentScroll::-webkit-scrollbar-thumb {
    background: #88addb;
    border-radius: 50px;
}

.detailsContainer {
    margin-top: 1%;
    margin-left: 1%;
    margin-bottom: 2%;
    padding-top: 1%;
    border: 1px solid #83838b;
    border-radius: 21px;
    box-shadow: -4px 4px 4px #00000029;
    background-color: #fff;
    padding: 28px 30px;
    height: 100%;
}

.heading {
    font-family: Comforta;
    color: #88addb;
    font-size: 25px;
    margin-bottom: 5%;
}
.middleInput {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    width: 100%;
    padding: 10px;
    color: #707070;
}

.inputBox {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    border-radius: 21px;
    width: 100%;
    margin-bottom: 2%;
    margin-top: 2%;
    margin-right: 31px;
    padding-right: 2%;
    padding-left: 15px;
    height: 44px;
}

.inputBox::-webkit-input-placeholder {
    font-size: 20px;
    margin-left: 20px;
}

.title {
    font-family: Comforta;
    font-size: 21px;
    color: #707070;
    margin-left: 3%;
    margin-bottom: -1%;
    margin-top: 2%;
}

.detailText {
    font-size: 0.9rem;
    margin-left: 2%;
}

.countryBox {
    box-shadow: -4px 4px 4px #00000029;
    border: 1px solid #83838b;
    border-radius: 21px;
    width: 100%;
    padding: 10px;
    margin-bottom: 2%;
    margin-top: 2%;
    padding-left: 15px;
    padding-right: 15px;
    height: 44px;
    margin-right: 31px;
}

.countryBox:focus {
    outline: none !important;
    border-color: #83838b;
}

.confirmBlock {
    margin-top: 31px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.clear {
    color: #88addb;
    font-size: 18px;
    font-family: ComfortaBold;
    cursor: pointer;
}

.arrows {
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
        white no-repeat calc(98% - 8px) !important;
    -webkit-appearance: none;
}
