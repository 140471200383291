.containerInactive {
    text-align: center;
    align-items: flex-end;
    width: 71px;
    background-color: #a5d8ed;
    line-height: 83px;
    border-radius: 23px 0px 0px 23px;
    margin-bottom: 3vh;
    margin-left: auto;
    cursor: pointer;
    height: 9vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
}

.containerActive {
    text-align: center;
    align-items: flex-end;
    /* height: 83px; */
    width: 71px;
    background-color: #fabf5e;
    line-height: 83px;
    border-radius: 23px 0px 0px 23px;
    margin-bottom: 3vh;
    margin-left: auto;
    cursor: pointer;
    height: 9vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
}

.lessonPopover {
    width: 137px !important;
    background-color: #f8f9fa;
}
#bar {
    width: 4px;
    background-color: #fabf5e;
    border-radius: 3px;
    float: left;
    margin-right: 5px;
}

.buttonInActive {
    width: 90%;
    background-color: transparent;
    height: 40px;
    font-family: CatalinaClementeRegular;
    margin-bottom: 8px;
    border: 1px solid #fabf5e;
    border-radius: 4px;
    font-size: 18px;
}
.buttonInActive:focus {
    outline: none;
}
.buttonActive {
    width: 90%;
    border: none;
    background-color: #fabf5e;
    height: 40px;
    font-family: CatalinaClementeRegular;
    margin-bottom: 8px;
    border: 1px solid #fabf5e;
    color: black;
    border-radius: 4px;
    font-size: 18px;
}

.buttonActive:focus {
    outline: none;
}
