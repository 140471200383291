.ImageModal {
    display: flex;
    justify-content: center;
    align-items: center;

    .ImageModalBody {
        border: 0;
        background-color: #f3f3f3;
        box-shadow: 4px 4px 4px #00000029;
        padding: 30px 35px;
        border-radius: 21px;
        width: 50rem;
        height: 60vh;

        display: flex;
        flex-flow: column;
        justify-content: space-between;

        .CarouselWrapper {
            height: 100%;
            display: flex;

            .CarouselItem {
                height: 100%;

                img,
                video {
                    height: 100%;
                    object-fit: contain;
                    max-height: calc(50vh - 70px);
                    max-width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                }
            }
        }

        .ImageInputModalSelectedFilesContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;

            .navigationIcon,
            .removeIcon {
                cursor: pointer;
            }

            .zeroOpacity {
                opacity: 0;
                cursor: default;
            }

            .imagesListContainer {
                display: flex;
                align-items: center;
                gap: 1rem;

                .imageContainer {
                    display: flex;
                    flex-flow: column;
                    cursor: pointer;

                    span {
                        align-self: flex-end;
                        z-index: 1;
                        margin-bottom: -1.5rem;
                        margin-right: 0.3rem;
                    }

                    img,
                    video {
                        height: 80px;
                        width: 100%;
                        max-width: 160px;
                    }
                }
            }
        }
    }

    .ImageModalBody:focus {
        outline: none;
    }
}
