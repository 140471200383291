.landingContainer {
    .landingPage {
        padding-top: 3rem;
        color: #002b4a;
        background-color: #ffffff !important;

        .userName {
            font-family: ComfortaBold;
            text-align: center;
            margin-top: 2rem;
            font-size: 1.3em;
            padding: 0 1.5rem;
        }

        .upcomingClassesListOuterContainer {
            background-color: #ffffff;
            margin: 0rem 1rem;
            border-radius: 20px;
            padding: 2rem 1.5rem;

            .upcomingClassesListHeader {
                color: #707070;
                text-align: center;
                font-weight: 600;
            }

            .upcomingClassesListContainer {
                overflow: scroll;
                max-height: calc(100vh - 24rem);

                .upcomingClassContainer {
                    background-color: #e9f3fb;
                    color: #707070;
                    margin-top: 1rem;
                    border-radius: 20px;
                    padding: 0.8rem 1rem;

                    display: flex;
                    gap: 2rem;

                    .classDateContainer {
                        display: flex;
                        flex-flow: column;

                        .classDate {
                            text-align: center;
                        }

                        .chatIcon {
                            height: 2.2rem;
                            margin-top: 0.4rem;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .browseBtn {
            font-family: ComfortaBold;
            font-size: 1rem;
            margin: 0 auto;
            display: block;
            margin-bottom: 10px;
        }
        .prefer {
            @extend .browseBtn;
            color: #88addb;
            cursor: pointer;
            text-align: center;
        }
    }
}

.teacherLandingContainer {
    .landingPage {
        background-color: #fae3db;
        height: auto;
    }
}

.studentLandingContainer {
    .landingPage {
        background-color: #e9f3fb;
        height: auto;
    }
}
