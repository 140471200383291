.multiselectinput {
    width: 250px;

    border: 1px solid red;

    display: flex;

    justify-content: space-between;

    position: relative;

    box-shadow: 4px 4px 4px #00000029;

    border: 1px solid #83838b;

    border-radius: 26px;

    padding: 5px;
    align-items: center;
}

.inputOption {
    display: flex;
    p {
        margin-top: 0px;
        padding: 5px;
        color: #707070;
    }
}

.optionInput {
    display: flex;

    align-items: center;

    margin-right: 5px;
}

.dropdownIcon {
    cursor: pointer;

    background-color: #88addb;

    color: #fff;

    border-radius: 50%;

    height: 25px;

    min-width: 25px;
}

.dropdown {
    position: absolute;

    left: 0px;

    top: 40px;

    background-color: #c8e8e5;

    width: 100%;

    color: #707070;

    border-radius: 20px;

    z-index: 8687;
    padding: 10px 10px 10px 10px;
    p {
        margin-top: 0px;
    }
}

.option {
    cursor: pointer;
    padding: 1px;
}
